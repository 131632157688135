<template>
    <div class="eco-header">
        <div class="eco-logo hasCursor" :class="logoBgMode" @click="navTo('home')">
            <img :src="logoModeUrl" alt="logo">
        </div>
        <div class="eco-header-nav">
            <div v-if="isCore && ($route.name !== 'users' && $route.name !== 'agencies')" id="agency_selector">
                <Select2
                    ref="agencySelector"
                    v-model="activeAgencyId"
                    :options="selectAgencies"
                    :settings="{
                        templateResult: companyPreview,
                        templateSelection: selectionPreview,
                        escapeMarkup: function(stuff){return stuff},
                        //initSelection: bindOptGroupClickEvent
                    }"
                    @select="companyPicked($event)"
                />
            </div>

            <div v-if="userCompany.attributes.isAgency &&
                       !isInWizzard &&
                       !isInAdmin &&
                       !isInBilling" id="company_selector">
                <Select2
                    ref="select2Component"
                    v-model="activeCompanyId"
                    :options="selectCompanies"
                    :settings="{
                        templateResult: companyPreview,
                        templateSelection: selectionPreview,
                        escapeMarkup: function(stuff){return stuff},
                        //initSelection: bindOptGroupClickEvent
                    }"
                    @select="companyPicked($event)"
                />
            </div>



            <div class="client-controls"  v-if="!isInWizzard && !isInAdmin">
                <span v-if="isSuspended" class="small text-warn">{{$ml.get('CLIENT_IS_SUSPENDED')}}</span>
                <button-field
                    v-if="false"
                    @click="suspendClient()"
                      circle
                      outline
                      v-tooltip="$ml.get('SUSPEND_CLIENT')"
                      icon="moon"
                      class="table-action-btn"></button-field>
                <div class="qf-vertical-separator"></div>
            </div>

            <div class="search">{{$ml.get('Search')}}</div>
            <ul class="main-nav">
                <li style="display:none">
                    <font-awesome-icon icon="bell"/>
                    <div class="notification"></div>
                </li>
                <li v-click-outside="clickOutside">
                    <span class="hello">{{$ml.get('Hello_user')}}<br/>{{user.attributes.firstName + ' ' + user.attributes.lastName}}</span>
                    <button class="profile"
                            @click="profileDropdown">
                        <span class="profile-avatar">
                            <img :src="user.attributes.avatar._url"
                                 :alt="user.attributes.username"
                                 v-if="user.attributes.avatar">
                            <font-awesome-icon color="black" icon="user" v-else/>
                        </span>
                    </button>
                    <!--<transition enter-active-class="animated fadeIn faster"
                                leave-active-class="animated fadeOut faster"
                                mode="out-in">-->
                        <div class="profile-dropdown"
                             v-show="ProfileDropdownActive">
                            <ul class="font-bebas-neue">
                                <li>
                                    <router-link :to="{name: 'billing'}">
                                        <font-awesome-icon icon="wallet"/>{{$ml.get('BILLING')}}
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{name: 'profile'}">
                                    <font-awesome-icon icon="cogs"/>{{$ml.get('ACCOUNT_SETTINGS')}}
                                    </router-link>
                                </li>
                                <li>
                                    <a href="#" @click.prevent="logout">
                                    <font-awesome-icon icon="sign-out-alt"/>{{$ml.get('LOG_OUT')}}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    <!--</transition>-->
                </li>
            </ul>
        </div>

    </div>
</template>

<script>
    import Select2 from 'v-select2-component';
    import moment from "moment";
    import store from "@/store";
    import Parse from 'parse';
    //import logoSrc from "@/assets/qfecard-logo-white.svg";
    // import {
    //     BDropdown,
    //     BDropdownItem,
    //     BIconThreeDots
    // } from 'bootstrap-vue'
    export default {
        name: "DashboardLayoutHeader",
        components: {
            Select2,
            // BDropdown,
            // BDropdownItem,
            // BIconThreeDots
        },
        computed: {
            impersonatingAgency(){
                let curUser = Parse.User.current();
                return curUser.attributes.impersonatingAgency;
            },

            routeAgency(){
                return this.$route.params.agencyId;
            },

            isInWizzard(){
                if(this.$route.name == 'client-add'){
                    return true;
                }else {
                    return false;
                }
            },
            isInAdmin(){
                if(this.$route.path.includes('admin')){
                    return true;
                }else {
                    return false;
                }
            },
            isInBilling(){
                if(this.$route.name == 'profile' || this.$route.name == 'billing'){
                    return true;
                }else {
                    return false;
                }
            },
            selectAgencies(){
                let selectData=[];

                if (this.masterAgencies) {
                    this.masterAgencies.forEach(oneAgency => {
                        let oneLine = {
                            id: oneAgency.id,
                            text: oneAgency.attributes.name,
                            fullData: oneAgency.attributes,
                            companyObj: oneAgency,
                            isAgency: true
                        };
                        selectData.push(oneLine);
                    });
                }
                return selectData;
            },
            selectCompanies() {
                let selectData = [];

                if (this.allClientCompanies) {
                    this.allClientCompanies.forEach(oneAgency => {
                        let oneLine = {
                            id: oneAgency.id,
                            text: oneAgency.attributes.name,
                            fullData: oneAgency.attributes,
                            companyObj: oneAgency,
                            isAgency: false
                        };
                        selectData.push(oneLine);
                    });
                }

                let ownLine = {};

                if(this.userCompany && !this.userCompany.attributes.core){
                    ownLine = {
                        id: this.userCompany.id,
                        text: this.userCompany.attributes.name,
                        fullData: this.userCompany.attributes,
                        companyObj: this.userCompany,
                        suspended: this.userCompany.attributes.suspended
                    };
                }else {
                    if(this.activeAgency.id){
                        ownLine = {
                            id: this.activeAgency.id,
                            text: this.activeAgency.attributes.name,
                            fullData: this.activeAgency.attributes,
                            companyObj: this.activeAgency,
                            suspended: this.activeAgency.attributes.suspended
                        };
                    }

                }

                if(ownLine.id) {
                    selectData.push(ownLine);
                }

                return selectData;

            },

            user() {
                if(this.$store.state.user){
                    return this.$store.state.user
                }else{
                    return {
                        attributes: {
                            firstName: '',
                            lastName: ''
                        }
                    }
                }
            },

            trialDaysRemaining() {
                let hasTrial = this.user ? this.user.attributes.trialUntil : false;
                if(hasTrial) {
                    let remainingTrial = -1 * moment().diff(store.state.user.attributes.trialUntil, 'days');
                    return remainingTrial;
                } else  {
                    return 0;
                }
            },

            clientCompany() {
                return this.$store.state.cardCompany;
            },

            isSuspended() {
                if(this.clientCompany){
                    return this.clientCompany.attributes.suspended;
                }else{
                    return false;
                }
            },

            allClientCompanies() {
                return this.$store.state.agencyClients;
            },

            masterAgencies() {
                return this.$store.state.masterAgencies;
            },

            logoBgMode(){
                let mode = 'dark';

                let isAgency = this.userCompany.attributes ? this.userCompany.attributes.isAgency : false;
                let clientOf = this.userCompany.attributes ? this.userCompany.attributes.clientOf : null;

                let parentHasLightBG = clientOf ? clientOf.attributes.lightLogoBg : false;


                if(this.userCompany) {

                    if( (isAgency && this.userCompany.attributes.lightLogoBg) ||
                        (!isAgency && parentHasLightBG)
                    ) {
                        mode = 'light'
                    }

                }
                return mode;
            },

            logoModeUrl(){
                let logoSrc = require('../../assets/qfecard-logo-white.svg');

                if(this.userCompany){
                    if(this.userCompany.attributes.isAgency && this.userCompany.attributes.agencyPanelLogo) {
                        logoSrc = this.userCompany.attributes.agencyPanelLogo._url;
                    } else {
                        let parentCompany = this.userCompany.attributes.clientOf;
                        if(parentCompany && parentCompany.attributes.agencyPanelLogo){
                            logoSrc = parentCompany.attributes.agencyPanelLogo._url;
                        }
                    }
                }else {
                    if(this.mode !== 'production'){
                        logoSrc = require('../../assets/qfecard-logo-white-dev.svg');
                    }
                }
              return logoSrc;
            },

            userCompany(){
                let comp = this.$store.state.userCompany ? this.$store.state.userCompany : {id: null, attributes: {isAgency: false}};
                return comp;
            },
        },
        data() {
            return {
                activeCompanyId: '',
                activeAgencyId: '',

                allAgencyChoices: [],

                activeAgency: {},

                //userCompany: {},

                activeAgencyClients: [],
                clientChoices: [],


                ProfileDropdownActive: false,
                ClientPopupActive: false,
                mode: process.env.VUE_APP_MODE,
                avatarPlaceholder: require('../../assets/noun-empty.svg'),
                companyPlaceholder: require('../../assets/icon_bw.png'),
                trialNoticeOpen: false,
                isCore: false,
            }
        },
        async created(){
            await this.$store.dispatch('getCompany').then((userCompany) => {
                // this.userCompany = userCompany;
                if(userCompany.attributes.core){

                    this.isCore = true;

                    this.$store.dispatch('getAllAgencies').then(() => {

                        let primaryAgency = this.masterAgencies.find(agency => agency.attributes.priority == 100);

                        if(this.$route.params.agencyId) {
                            this.activeAgencyId = this.$route.params.agencyId;
                        } else if(this.impersonatingAgency) {
                            this.activeAgencyId = this.impersonatingAgency.id;
                        } else {
                            this.activeAgencyId = this.primaryAgency.id;
                        }

                        if(userCompany.attributes.impersonating && userCompany.attributes.impersonating.id && this.idIsInList(userCompany.attributes.impersonating.id)){
                            this.activeCompanyId = userCompany.attributes.impersonating.id;
                        } else {
                            this.activeCompanyId = primaryAgency ? primaryAgency.id : this.$store.state.userCompany.id;
                        }

                    });

                }else {

                    this.$store.dispatch('getAgencyClients').then(() => {

                        if(userCompany.attributes.impersonating && userCompany.attributes.impersonating.id && this.idIsInList(userCompany.attributes.impersonating.id)){
                            this.activeCompanyId = userCompany.attributes.impersonating.id;
                        } else {
                            this.activeCompanyId = this.$store.state.userCompany ? this.$store.state.userCompany.id : null;
                        }
                    });

                }
            });

        },
        methods: {
            idIsInList(id){
              return this.allClientCompanies.find(company => company.id == id);
            },
            navTo(destination){
                if(destination == 'home' && this.isAdmin) {
                    this.$router.push({name: 'adminCards'});
                }else {
                    this.$router.push({name: destination});
                }
            },

            selectionPreview(selection) {
                let markup = '';

                if (selection.children && selection.children.length > 0) {
                    // It's an agency with children
                    let agency = selection.companyObj.attributes;
                    if (!agency.logo) {
                        agency.logo = {
                            _url: this.companyPlaceholder
                        }
                    }

                    markup += `<div class="agency selection">`;
                    markup += `<span class="profile-avatar">`;
                    markup += `<img class="comp-logo" src="` + agency.logo._url + `" />`;
                    markup += `</span>`;
                    markup += `<h4>` + selection.text + `</h4>`;
                    markup += `</div>`;
                } else if (selection.fullData) {
                    let item = selection.fullData;

                    if (!item.logo) {
                        item.logo = {
                            _url: this.companyPlaceholder
                        }
                    }
                    markup += selection.isOwnCompany ? `<div class="company selection own">` : `<div class="company selection">`;

                    markup += `<span class="profile-avatar">`;
                    markup += `<img class="comp-logo" src="` + item.logo._url + `" />`;
                    markup += `</span>`;
                    if(item.clientOf && !selection.fullData.isAgency){
                        markup += `<div class="item-name">`;
                        markup += `<h4>` + item.name + `</h4>`;
                        markup += `<div class="client-of-name">`+item.clientOf.attributes.name+`</div>`;
                        markup += `</div>`;
                    }else {
                        markup += `<h4>` + item.name + `</h4>`;
                    }
                    markup += `</div>`;
                }

                return markup;
            },

            companyPreview(choice) {
                let markup = '';

                if (choice.isAgency) {
                    let agency = choice.fullData;
                    if (!agency.logo) {
                        agency.logo = {
                            _url: this.companyPlaceholder
                        }
                    }

                    markup += `<div class="agency" data-agency-id="${choice.id}">`;
                    markup += `<span class="profile-avatar">`;
                    markup += `<img class="comp-logo" src="` + agency.logo._url + `" />`;
                    markup += `</span>`;
                    markup += `<h4 class="d-inline">` + choice.text + `</h4>`;
                    markup += `</div>`;
                } else if (choice.fullData) {
                    let item = choice.fullData;

                    if (!item.logo) {
                        item.logo = {
                            _url: this.companyPlaceholder
                        }
                    }

                    if (item.suspended) {
                        markup = `<div class="company suspended">`;
                    } else {
                        markup += choice.isOwnCompany ? `<div class="company own">` : `<div class="company">`;
                    }

                    markup += `<span class="profile-avatar">`;
                    markup += `<img class="comp-logo" src="` + item.logo._url + `" />`;
                    markup += `</span>`;

                    if(item.clientOf && !item.isAgency){
                        markup += `<div class="item-name">`;
                        markup += `<h4>` + item.name + `</h4>`;
                        markup += `<div class="client-of-name">`+item.clientOf.attributes.name+`</div>`;
                        markup += `</div>`;
                    }else {
                        markup += `<h4>` + item.name + `</h4>`;
                    }
                    markup += `</div>`;
                }

                return markup;
            },

            companyPicked(selectData){
                let clientComp = selectData.companyObj;
                let self = this;
                this.$store.dispatch('changeImpersonation', clientComp).then(function(){
                    self.$store.commit('setCardCompany', clientComp);
                    self.$store.dispatch('getEmployeeCardCount').then(() => {
                    });
                    //self.$store.dispatch('setEmployeeCardPage', 1);
                    self.$store.dispatch('getEmployeeCards').then(() => {
                        self.$store.dispatch('getCompAddresses');
                    });
                    self.$store.dispatch('getCardConfig');
                }).catch(function(e){
                    self.$checkTokenExp(e)
                });
                this.ClientPopupActive = false;
            },
            profileDropdown() {
                this.ProfileDropdownActive = !this.ProfileDropdownActive;
            },
            switchClient() {
                this.ClientPopupActive = !this.ClientPopupActive;
                this.$store.commit('closeRightModal');
            },
            clickOutside() {
                if (this.ProfileDropdownActive) {
                    this.profileDropdown();
                }
            },
            changeClient(clientComp){
                let self = this;
                this.$store.dispatch('changeImpersonation', clientComp).then(function(){
                    self.$store.commit('setCardCompany', clientComp);
					self.$store.dispatch('getEmployeeCardCount').then(() => {
					});
					self.$store.dispatch('setEmployeeCardPage', 1);
                    self.$store.dispatch('getEmployeeCards').then(() => {
                        self.$store.dispatch('getCompAddresses');
                    });
                    self.$store.dispatch('getCardConfig');
                });
                this.ClientPopupActive = false;
            },
            logout() {
                this.$store.dispatch('logout').then(() => {
                    this.$store.commit('setIsAuthenticated', false);
                    this.$store.commit('setUser', null);
                    this.$router.push('/auth');
                });
            },
            suspendClient(){
                let isSuspended = this.clientCompany.attributes.suspended;
                this.clientCompany.set('suspended', !isSuspended);
                this.clientCompany.save();
            }
        },
        watch: {
            activeAgencyId(newVal){
                if(newVal){
                    let userCompany = this.$store.getters['getUserCompany'];

                    this.$store.dispatch('getClientsOfAgency', {agencyId: newVal}).then(() => {

                        if(userCompany.attributes.impersonating && userCompany.attributes.impersonating.id && this.idIsInList(userCompany.attributes.impersonating.id)){
                            this.activeCompanyId = userCompany.attributes.impersonating.id;
                        } else {
                            this.activeCompanyId = this.activeAgencyId;
                        }

                        this.$store.dispatch('setImpersonatingAgency', {agencyId: newVal});
                        this.$store.commit('setAgencyId', newVal);
                        this.activeAgency = this.masterAgencies.find(agency => agency.id == newVal);
                    });


                }
            },
            routeAgency(newVal){
                if(newVal){
                    this.activeAgencyId = newVal;
                }
            },
            isInClients(newVal){
                if(newVal === false){
                    this.activeCompanyId = this.$store.state.cardCompany.id;
                }
            },
            clientCompany(newCompany){
                if(newCompany && newCompany.id){
                    this.activeCompanyId = newCompany.id;
                }
            },

        }
    }

</script>

<style>


#company_selector .select2-container--default .select2-selection--single .select2-selection__rendered,
#agency_selector .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 12px;
}

.company .item-name h4 {

}

.client-of-name {
    font-size: 10px;
    color: #6c757d;
}

.select2-container--default .select2-results__option .select2-results__option {
    padding-left: 1.25em;
}

.select2-results__option .agency {
    font-size: 12px;
    display: flex;
    align-content: center;
    justify-content: flex-start;
}

.agency h4 {
    margin: auto 0;
    color: black;
}

.company h4 {
    margin: auto 0;
    font-size: 12px;
    font-weight: inherit;
}

li.select2-results__option .company {
    padding-left: 1.25em;
}



.client_companies.side_picker {
    padding-left: 2px;
}

.side_picker .company {
    margin-bottom: 0;
    height: 41px;
    color: white;
    font-size: 10px;
    font-weight: 100;
}

#company_selector, #agency_selector{
    width: 250px;
    margin: 0 10px;
}

#company_selector > div,
#agency_selector > div{
    width: 100%;
}

#company_selector .select2-container,
#agency_selector .select2-container {
    width: 100% !important;
}

.search{display: none}

.hello {
    text-align: right;
    font-size: 12px;
    margin-right: 10px;
    padding-top: 2px;
}
.hello.right {
    text-align: left;
    font-size: 12px;
    margin-left: 10px;
    margin-right: 0;
}
.all_client_companies {
    visibility: hidden;
    opacity: 0;
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: hsla(0, 0%, 0%, 0.5);
    justify-content: center;
    align-items: baseline;
    z-index: 9999;
}
.all_client_companies.active {
    opacity: 1;
    visibility: visible;
}
.client_companies {
    max-height: calc(100% - 40px);
    margin: 20px;
    width: 700px;
    background: #fff;
    display: grid;
    height: auto;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(124px, 1fr));
    padding: 20px;
    overflow: auto;
    border-radius: 5px;
    transform:translateY(-100px);
    transition: transform .2s ease-out;
}
.all_client_companies.active .client_companies{
    transform:translateY(0px);
}
button.profile-client {
    display: flex;
    align-items: center;
}

.company.suspended {
    opacity: 0.5;
}

li.company {
    display: flex;
    flex-direction:column;
    padding: 10px;
    align-items: center;
    text-align: center;
    cursor: pointer;
}
li.company .profile-avatar {
    width: 80px;
    height: 80px;
}

li.company h3 {
    font-size: 16px;
    margin: 10px 0 0 0;
}
.selected_company {
    display: flex;
    padding: 0 25px;
}

.qf-options ul li {
    font-size: 0.75em;
}

.qf-options ul {
    border-top: none;
    border-radius: 0;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 10%);
}
.qf-vertical-separator {
    display: inline-block;
}

.text-warn {
    color:var(--color-error-dark);
}

.select2-container .own .profile-avatar {
    border-color: var(--color-primary);
}
</style>
