export const globalActions = {
	async loadUserData({ commit, dispatch }) {

		commit('setUserDataLoaded', true);
		try {
			await dispatch('currentUser')
			await dispatch('getCompany');

		} catch (error) {
			console.error('Error loading user data', error);
			throw error;
		}
	}
}
