import Vue from 'vue'
import Vuex from 'vuex'
import {actions} from '../services/actions'

import * as deviceDetector from 'mobile-device-detect';
import moment from "moment";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        deviceData: deviceDetector,
        isAuthenticated: false,
        globalLoader: false,
        loadUserDataFinished: false,
        user: null,
        userCompany: null,
        isCore: false,
        isSuspended: false,
        trialRemaining: null,
        cardCompany: null,
        agencyCompany: null,
        agencyId: null,
        cardConfig: {
            attributes : {
                template: {
                    attributes: {
                        nice_name : ""
                    }
                }
            }
        },
        cardTemplates: [],
        selectedTemplate: null,
        initialTemplate: {
            id: null,
            attributes: {}
        },
        selectedNewTemplate: {
            id: null,
            attributes: {}
        },
        dashboardModalComponent: {
            state: false,
            name: 'DashboardEmployeeCardsForm',
            data: null
        },
        employeeCards: [],
        employeeCardCount: 0,
        employeeCardPage: 1,
        employeeCardsPP: 25,
        privateAgencyClients: [],
        agencyClients: [],
        masterAgencies: [],
        officeAddresses: [],
        fontFamilies: [],
        visibleUsers: [],
        selectedInvoices: [],
        usersTotalCount: 0,
        usersPP: 25,
        usersPage: 1,
        myPricing: {
            attributes: {}
        },
    },
    getters: {
        isUserDataLoaded(state) {
             if( (state.user !== null && state.userCompany !== null) || state.loadUserDataFinished) {
                 return true;
             } else {
                 return false;
             }
        },
        selectedModalData: state => field => {
            if (
                state.dashboardModalComponent.data !== null &&
                state.dashboardModalComponent.data.id !== null &&
                state.dashboardModalComponent.data.ids === undefined
            ) {
                return state[field].find(obj => {
                    return obj.id === state.dashboardModalComponent.data.id;
                });
            } else if (
                state.dashboardModalComponent.data !== null &&
                state.dashboardModalComponent.data.ids !== null &&
                state.dashboardModalComponent.data.id !== null
                ) {
                return state[field].filter(obj => {
                    return state.dashboardModalComponent.data.ids.includes(obj.id);
                });
            } else {
                return null;
            }
        },
        getCardCompany(state) {
            return state.cardCompany;
        },
        getUserCompany(state) {
            return state.userCompany;
        },
        getIsCore(state) {
            return state.isCore;
        },
        getIsSuspended(state) {
            return state.isSuspended;
        },
        getRemainingTrial(state) {
            return state.trialRemaining;
        },
        getAgencyId(state) {
            return state.agencyId;
        },
        getAgencyCompany(state) {
            return state.agencyCompany;
        },
        getVisibleUsers(state){
            return state.visibleUsers;
        },
        getSelectedTemplate(state) {
            return state.selectedTemplate;
        }
    },
    mutations: {
        setMyPricing(state, data) {
            state.myPricing = data;
        },
        setUserDataLoaded(state, data) {
            state.loadUserDataFinished = data;
        },
        resetState (state) {
            Object.assign(state, {
                deviceData: deviceDetector,
                isAuthenticated: false,
                globalLoader: false,
                user: null,
                userHasTrial: false,
                isSuspended: false,
                trialRemaining: null,
                userCompany: null,
                isCore: false,
                cardCompany: null,
                cardConfig: undefined,
                cardTemplates: [],
                selectedTemplate: {
                    id:''
                },
                initialTemplate: {
                    id: null,
                    attributes: {}
                },
                selectedNewTemplate: {
                    id: null,
                    attributes: {}
                },
                dashboardModalComponent: {
                    state: false,
                    name: 'DashboardEmployeeCardsForm',
                    data: null
                },
                employeeCards: [],
                employeeCardCount: 0,
                employeeCardPage: 1,
                employeeCardsPP: 25,
                agencyClients: [],
                officeAddresses: [],
                fontFamilies: [],
                visibleUsers: [],
                selectedInvoices: [],
            });
        },
        setSelectedInvoices(state, data) {
            state.selectedInvoices = data;
        },
        setVisibleUsers(state, data){
            state.visibleUsers = data;
        },
        setDeviceData(state, data) {
            state.deviceData = data;
        },
        setIsAuthenticated(state, data) {
            state.isAuthenticated = data;
        },
        setGlobalLoader(state, data) {
            state.globalLoader = data;
        },
        setUser(state, data) {
            state.user = data;
        },
        setUserCompany(state, data) {
            state.userCompany = null;
            state.userCompany = data;
            if(data.attributes.impersonating){
                state.cardCompany = data.attributes.impersonating;
            }else{
                state.cardCompany = data;
            }

            if(state.userCompany.attributes){
                state.isCore = state.userCompany.attributes.core ? true : false;
            }else {
                state.isCore = false;
            }

            if(state.userCompany.attributes){

                //SET suspended status
                if(state.userCompany.attributes.suspended || state.user.attributes.suspended){
                    state.isSuspended = true;
                }else {
                    state.isSuspended = false;
                }

                //Compute remaining trial days
                let userTrial = state.user.attributes.trialUntil ? moment(state.user.attributes.trialUntil) : null;
                let companyTrial = state.userCompany.attributes.trialUntil ? moment(state.userCompany.attributes.trialUntil) : null;

                let maxTrial = null;

                // Check if both dates are valid
                if (userTrial && userTrial.isValid() && companyTrial && companyTrial.isValid()) {
                    maxTrial = moment.max(userTrial, companyTrial);
                } else if (userTrial && userTrial.isValid()) {
                    // Only user trial date is valid
                    maxTrial = userTrial;
                } else if (companyTrial && companyTrial.isValid()) {
                    // Only company trial date is valid
                    maxTrial = companyTrial;
                }

                if (maxTrial) {
                    let remainingTrial = maxTrial.diff(moment(), 'days');
                    state.trialRemaining = remainingTrial;
                } else {
                    state.trialRemaining = null;
                }
            }

        },
        setCardCompany(state, data) {
            state.cardCompany = data;
        },
        setAgencyCompany(state, data) {
            state.agencyCompany = data;
        },
        setAgencyId(state, data) {
            state.agencyId = data;
        },
        setCardConfig(state, data) {
            state.cardConfig = data;
        },
        setCardTemplates(state, data) {
            state.cardTemplates = data;
        },
        setFontFamilies(state, data) {
            state.fontFamilies = data;
        },
        setInitialTemplate(state, data){
            state.initialTemplate = data;
        },
        setActiveTemplate(state, data){
            state.selectedTemplate = data;
        },
        setActiveNewTemplate(state, data){
            state.selectedNewTemplate = data;
        },
        toggleDashboardModal(state, data = null) {
            if (data !== null && !state.dashboardModalComponent.state) {
                state.dashboardModalComponent.name = data.name;
                state.dashboardModalComponent.data = data.data;
            }
            state.dashboardModalComponent.state = !state.dashboardModalComponent.state;
        },
        closeRightModal(state) {
            state.dashboardModalComponent.state = false;
        },
        setEmployeeCards(state, employees) {
            state.employeeCards = employees;
        },
        setEmployeeCardCount(state, count){
            state.employeeCardCount = count;
        },
        setEmployeeCardPage(state, pagenum){
            state.employeeCardPage = pagenum;
        },
        setUsersPage(state, pagenum){
            state.usersPage = pagenum;
        },
        setEmployeeCardsPP(state, cardsperpage){
            state.employeeCardsPP = cardsperpage;
        },
        setUsersPP(state, cardsperpage){
            state.usersPP = cardsperpage;
        },
        setTotalUserCount(state, count){
            state.usersTotalCount = count;
        },
        setAgencyClients(state, clients) {
            state.agencyClients = [];
            state.agencyClients = clients;
        },
        setPrivateAgencyClients(state, clients) {
            state.privateAgencyClients = [];
            state.privateAgencyClients = clients;

            state.agencyClients = [];
            state.agencyClients = clients;
        },
        setMasterAgencies(state, agencies) {
            state.masterAgencies = [];
            state.masterAgencies = agencies.filter(agency => {
                return agency.attributes.core !== true;
            });
        },
        setOfficeAddresses(state, addresses) {
            state.officeAddresses = addresses;
        }

    },
    actions,
    modules: {},
    methods: {

    }

});
