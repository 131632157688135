import Parse from 'parse'
import moment from 'moment'

export const PricingService = {

    confirmSubscription(context){
        let curUser = Parse.User.current();
        curUser.set('trialUntil', null);
        curUser.save();

        let userCompany = context.getters['getUserCompany'];
        userCompany.set('subscribedOn', moment().toDate());
        userCompany.set('trialUntil', null);
        return new Promise((resolve, reject) => {
            userCompany.save().then(response => {
               context.commit('setUserCompany', response);
               resolve(response)
            }).catch(error => {
                reject(error);
            })
        });

    },

    cancelSubscription(context){
        let userCompany = context.getters['getUserCompany'];
        let trialUntil = moment().add(30, 'days').toDate();
        userCompany.set('trialUntil', trialUntil);
        return new Promise((resolve, reject) => {
            userCompany.save().then(response => {
                context.commit('setUserCompany', response);



                resolve(response)
            }).catch(error => {
                reject(error);
            })
        });
    },

    getMyPricingTiers(context) {

        let curUser = Parse.User.current();

        let PricingTier = Parse.Object.extend('PricingTier');
        let query = new Parse.Query(PricingTier);
        let compPointer = {"__type": "Pointer", "className": "Company", "objectId":  curUser.attributes.company.id}
        query.equalTo('relatedCompany', compPointer);

        return new Promise((resolve, reject) => {
            query.first().then(response => {
                context.commit('setMyPricing', response);
                resolve(response)
            }).catch(error => {
                self.$checkTokenExp(error);
                reject(error);
            })

        });

    },

    getCompanyPricing(context, companyId) {

        let PricingTier = Parse.Object.extend('PricingTier');
        let query = new Parse.Query(PricingTier);
        let compPointer = {"__type": "Pointer", "className": "Company", "objectId":  companyId}
        query.equalTo('relatedCompany', compPointer);

        return new Promise((resolve, reject) => {

            query.first().then(response => {
                context.commit('setMyPricing', response);
                resolve(response)
            }).catch(error => {
                self.$checkTokenExp(error);
                reject(error);
            })

        });

    },
    savePricingTiers(context, data) {
        if(data.prices && data.prices.id){
            data.prices.set('pricingTier1', data.newPrices.pricingTier1);
            data.prices.set('pricingTier2', data.newPrices.pricingTier2);
            data.prices.set('pricingTier3', data.newPrices.pricingTier3);
            data.prices.set('pricingTier4', data.newPrices.pricingTier4);
            data.prices.set('pricingTier5', data.newPrices.pricingTier5);
        }
        return new Promise((resolve, reject) => {
            data.prices.save().then(response => {
                context.commit('setMyPricing', response);
                resolve(response)
            }).catch(error => {
                self.$checkTokenExp(error);
                reject(error);
            })
        });
    }

};
