<template>
    <div class="container">
        <div class="auth-form wide max-height-overflow">
            <div class="row header-row">
                <div class="col text-center login-logo-row">
                    <img src="@/assets/qfecard-logo-white.svg" alt="logo">
                </div>
                <div class="eco-log-out-control">
                    <font-awesome-icon
                        icon="times"
                        class="text-white cursor-pointer"
                        @click="returnToProfile"
                        v-tooltip="$ml.get('RETURN_TO_PROFILE')"
                        size="lg"></font-awesome-icon>
                </div>
            </div>

            <div class="form-fields">
                <div id="link-invalid" class="text-center">

                    <h3 class="my-0">QFECARD Subscription terms and pricing</h3>
                    <div class="space"></div>
                    <div class="welcome-separator"></div>


                    <div class="">
                        <h4 class="text-left mb-0">1. Basic Definitions</h4>
                        <p class="text-left small mt-0">Qfecard is a subscription service that is billed monthly pro rata based on the highest number of active business cards used by the customer in the previous month. Billing always takes place on the first days of the astronomical month.</p>
                        <p class="text-left  small mt-0">An active business card is an entry that exists in the qfecard administration panel under the "Employee Cards" tab and was created before the payroll date, regardless of whether the card te sent or installed on the employee's end device.</p>
                        <p class="text-left  small mt-0">For billing purposes the maximum number of active cards reached during the month is taken into account. The number of cards is checked and logged daily at 03:00 AM CET time. The maximum value logged for the given month is the value that will be used for the monthly billing calculations and the selection of your Tier.</p>

                        <h4 class="text-left mb-0">2. Tariff</h4>
                        <p class="text-left  small mt-0">Subscription rates are divided into five billing tiers (T1 - T5) based on the number of active cards for each month. Your current tier is highlighted in the table below.</p>

                        <pricing-table class="mb-3"></pricing-table>


                        <h4 class="text-left mb-0">3. Cancellation of subscription</h4>
                        <p class="text-left  small mt-0">This Qfecard service subscription is concluded for an indefinite period.</p>
                        <p class="text-left  small mt-0">The subscription can be terminated by you, unilaterally, by clicking the "Cancel subscription" button in your account settings. You will be invoiced for the amount of cards at the day of cancellation and your account will remain active until the end of the current billing cycle. If you wish to resubscribe, you may do so at any time.</p>

                        <div class="row justify-content-center mb-3">
                            <div class="col-12 pt-4">
                                <hr/>
                                <p class="small mb-0 text-center w-100">Do you need more details and a formal contract? Request it by <a href="#" @click="openSubscribe">following this link</a> and filling out the form.</p>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Parse from "parse";
    import PricingTable from "@/components/global/PricingTable.vue";
    import {VBTooltip} from "bootstrap-vue";

    export default {
        name: "TermsRegular",
        components: {
            PricingTable
        },
        directives: {
            'b-tooltip': VBTooltip
        },
        data() {
            return {
                confirm: false,
                loading: false,
            }
        },
        computed: {
            userCompany(){
                return this.$store.state.userCompany;
            },
        },
        methods: {

            openSubscribe(){

                let ulr_base = 'https://www.qfecard.com/confirm-registration/';
                let curUser = Parse.User.current();
                let compData = this.$store.state.userCompany.attributes;

                let params = {
                    email: curUser.attributes.email,
                    firstName: curUser.attributes.firstName,
                    lastName: curUser.attributes.lastName,
                    company: compData.name,
                    phone: compData.telephone,
                    address: compData.billingAddress,
                    billingEmail: compData.billingEmail,
                    vat: compData.vatNumber,
                    responsible: compData.responsible,
                    website: compData.website,
                    companyId: this.$store.state.userCompany.id,
                }

                if(compData.usedPromo && compData.usedPromo.get('code')){
                    params.promo = compData.usedPromo.attributes.code;
                }

                let paramsString = JSON.stringify(params);
                let encodedParams = btoa(paramsString);

                let url = ulr_base +'?data='+ encodedParams;

                window.open(url, '_blank');

            },
            returnToProfile() {
                this.$router.push({name: 'profile'})
            },
        }
    }
</script>

<style scoped>

    .max-height-overflow .form-fields{
        max-height: 80vh;
        overflow-y: auto;
        overflow-x: clip;
        padding-left: 20px;
    }

    .header-row {
        position: relative;
    }
    .eco-log-out-control {
        position: absolute;
        top: 12px;
        right: 10px;
        padding: 10px;
        cursor: pointer;
    }

    .auth-form.wide {
        max-width: 720px;
    }

    .container {
        min-height: 100vh;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .welcome-separator {
        width: 100%;
        border-top: 1px solid var(--color-secondary);
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .space {
        width: 100%;
        background: transparent;
        opacity: 0;
        width: 100%;
        height: 10px;
    }

    .welcome-message {
        font-size: 0.8em;
    }

    .form-loader, .form-fields {
        min-height: 290px;
    }

    .invitation-loading {
        width: 100%;
        text-align: center;
        padding-top: 100px;
    }

    .company-logo{
        object-fit: cover;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid #000000;
    }
</style>
