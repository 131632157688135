<template>
    <div class="eco-sidebar">
        <div class="eco-sidebar-wrapper">
            <ul class="eco-sidebar-nav font-bebas-neue" v-if="!isInWizzard && !isInClients && !isInBilling">
                <li>
                    <router-link :to="{name: 'home'}">
                        <span class="eco-sidebar-nav-icon"><font-awesome-icon icon="users"/></span> {{$ml.get('EMPLOYEE_CARDS')}}
                    </router-link>
                </li>

                <li v-if="false">
                    <router-link :to="{name: 'billingEstimate'}">
                        <span class="eco-sidebar-nav-icon"><font-awesome-icon icon="file-invoice-dollar"/></span> {{$ml.get('BILLING_ESTIMATE')}}
                    </router-link>
                </li>

                <li>
                    <router-link :to="{name: 'office-addresses'}">
                        <span class="eco-sidebar-nav-icon"><font-awesome-icon icon="map-marker-alt"/></span> {{$ml.get('COMPANY_COORDINATES')}}
                    </router-link>
                </li>
                <li>
                    <router-link :to="{name: 'cardDesign'}" v-if="canEditDesign">
                        <span class="eco-sidebar-nav-icon"><font-awesome-icon icon="palette"/></span> {{$ml.get('COMPANY_IDENTITY')}}
                    </router-link>
                </li>
                <li>
                    <router-link :to="{name: 'adminCards'}">
                        <span class="eco-sidebar-nav-icon"><font-awesome-icon icon="chart-bar"/></span> {{$ml.get('CARD_REPORTS')}}
                    </router-link>
                </li>
                <li v-if="isAdmin">
                    <router-link :to="{name: 'invoices'}" v-if="isAgency">
                        <span class="eco-sidebar-nav-icon"><font-awesome-icon icon="file-invoice"/></span> {{$ml.get('MENU_INVOICES')}}
                    </router-link>
                </li>
                <li>
                    <router-link :to="{name: 'clientUsers'}" v-if="isAgency">
                        <span class="eco-sidebar-nav-icon"><font-awesome-icon icon="user-tag"/></span> {{$ml.get('MENU_CLIENT_USERS')}}
                    </router-link>
                </li>
                <li v-if="false">
                    <router-link :to="{name: 'client-information'}">
                        <span class="eco-sidebar-nav-icon"><font-awesome-icon icon="user-tag"/></span> {{$ml.get('COMPANY_INFORMATION')}}
                    </router-link>
                </li>

            </ul>
        </div>
        <div class="goToClients" v-if="!userCompany.attributes.isAgency
                                        && userCompany.attributes.canUpgrade
                                        && !isInWizzard
                                        && !isInClients
                                        && !isInBilling">
            <button-field
                class="w-100"
                @click="openUpgrade()"
                icon="arrow-up"
                color="secondary">{{$ml.get('UPGRADE_TO_AGENCY')}}
            </button-field>
        </div>

        <div class="goToClients"
             v-if="userCompany.attributes.isAgency
                   && !isInWizzard && !isInClients
                   && userCompany.attributes.verified
            ">
            <button-field
                    class="w-100"
                    @click="navTo('clients')"
                    icon="cogs"
                    color="primary">{{$ml.get('ADMIN_AREA')}}
            </button-field>
        </div>

        <div class="version">v.{{version}}</div>
    </div>
</template>

<script>
    import Parse from 'parse';
    export default {
        name: "DashboardLayoutNav",
        data(){
          return {
              curUser: {
                  attributes: {
                      isAdmin: false,
                      canEditDesign: false,
                  }
              },
              ClientPopupActive: false,
              mode: process.env.VUE_APP_MODE,
          }
        },
        mounted() {
            this.curUser = Parse.User.current();
        },
        computed: {
            userCompany(){
              let comp = this.$store.state.userCompany ? this.$store.state.userCompany : {id: null, attributes: {isAgency: false}};
              return comp;
            },
            isInWizzard(){
              if(this.$route.name == 'client-add'){
                  return true;
              }else {
                  return false;
              }
            },
            isAgency(){
                if(this.userCompany && this.userCompany.attributes.isAgency) {
                    return true;
                }else {
                    return false;
                }
            },
            isAdmin(){
                if(this.curUser && this.curUser.attributes && this.curUser.attributes.isAdmin){
                    return true;
                }else {
                    return false;
                }
            },
            canEditDesign(){
                if(this.isAdmin || this.userCompany.attributes.isAgency || this.curUser.attributes.canEditDesign){
                    return true;
                }else {
                    return false;
                }
            },
            isInClients(){
                if(this.$route.name == 'clients'){
                    return true;
                }else {
                    return false;
                }
            },
            isInBilling(){
                if(this.$route.name == 'profile' || this.$route.name == 'billing'){
                    return true;
                }else {
                    return false;
                }
            },
            version () {
                return process.env.VUE_APP_VERSION;
            }
        },
        methods: {
            openUpgrade(){
                this.$router.push({name: 'profile'});
            },
            navTo(destination) {
                if(destination == 'home' && this.isAdmin) {
                    this.$router.push({name: 'adminCards'});
                }else {
                    this.$router.push({name: destination});
                }

            }
        }
    }
</script>

<style scoped>
    .goToClients{
        margin-top: auto;
        padding: 25px 15px;
    }
    .version {
        text-align: center;
        color: #777777;
        font-size: 10px;
        position: absolute;
        bottom: 0;
        padding: 6px 16px;
    }
</style>
