<template>
    <div class="container">
        <div class="auth-form" :class="isSuspended ? '' : 'wide'">
            <div class="row header-row">
                <div class="col text-center login-logo-row">
                    <img src="@/assets/qfecard-logo-white.svg" alt="logo">
                </div>
                <div class="eco-log-out-control">
                    <font-awesome-icon
                        icon="sign-out-alt"
                        class="text-white cursor-pointer"
                        @click="logout"
                        v-tooltip="$ml.get('SIGN_OUT')"
                        size="lg"></font-awesome-icon>
                </div>
            </div>

            <div class="form-fields">
                <div id="link-invalid" class="text-center">
                    <h3 class="my-0" v-if="isSuspended"><font-awesome-icon  icon="heart-broken"></font-awesome-icon> Your account is suspended!</h3>
                    <h3 class="my-0"  v-else-if="trialXPNiceDate"><font-awesome-icon  icon="heart-broken"></font-awesome-icon> Your trial has ended!</h3>
                    <h3 class="my-0"  v-else><font-awesome-icon  icon="check"></font-awesome-icon> Your account is now active!</h3>

                    <div class="space"></div>
                    <div class="welcome-separator"></div>

                    <div class="" v-if="isSuspended">
                        <p class="text-center">It appears that your qfecard account is no longer active.</p>
                        <p class="text-center">If you think this has happened in error, please contact the administrator who had on-boarded you.</p>
                        <hr/>
                        <div class="row justify-content-center mb-3">
                            <div class="col-4">
                                <button-field color="secondary"
                                              type="submit"
                                              block
                                              @click="logout"
                                              icon="sign-out-alt"
                                >
                                    {{$ml.get('LOG_OUT')}}
                                </button-field>
                            </div>
                        </div>
                    </div>

                    <div class="" v-else-if="trialXPNiceDate">
                        <p class="text-center small mb-0">Your account's trial period has expired on: <strong>{{trialXPNiceDate}}</strong></p>
                        <p class="text-center small mt-0">To continue your subscription you need to activate your account and confirm your subscription.</p>

                        <pricing-table class="mb-3" @level="setActiveLevel" @count="setActiveCardCount"></pricing-table>

                        <p class="text-center small my-0">Your account will be billed at the next billing cycle, starting on {{nextBillingDate}}.</p>
                        <p class="text-center small mt-0">Based on your current usage, your estimated bill will be <span class="text-primary">{{ {currency: 'EUR', amount:billableEstimate } | currency }}</span></p>
                        <div class="my-3">
                            <checkbox-field v-model="confirm" color="secondary">I agree to become an active paying customer for qfecard in accordance<br/> with the standard <a href="/terms-regular" target="_blank">Terms and conditions</a></checkbox-field>
                        </div>

                        <div class="row justify-content-center mb-3">
                            <div class="col-4">
                                <button-field color="secondary"
                                              type="submit"
                                              block
                                              @click="confirmSubscription"
                                              icon="check"
                                              :disabled="!confirm"
                                >
                                    {{$ml.get('CONFIRM_SUBSCRIPTION')}}
                                </button-field>
                            </div>
                            <div class="col-12 pt-4">
                                <hr/>
                                <p class="small mb-0">Need a formal contract? Request it by <a href="#" @click="openSubscribe">following this link</a> and filling out the form.</p>
                            </div>
                        </div>

                    </div>

                    <div class="pt-3" v-else>
                        <p class="text-center my-1">You can now enjoy the full functionality of the platfrom!</p>
                        <div class="row justify-content-center my-3 pt-3">
                            <div class="col-4">
                                <button-field color="secondary"
                                              type="submit"
                                              block
                                              @click="backToDashboard"
                                              icon="home"
                                >
                                    {{$ml.get('BACK_TO_DASHBOARD')}}
                                </button-field>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    import Parse from "parse";
    import PricingTable from "@/components/global/PricingTable.vue";

    import {VBTooltip} from "bootstrap-vue";

    export default {
        name: "ProfileSuspended",
        components: {
            PricingTable
        },
        directives: {
            'b-tooltip': VBTooltip
        },
        data() {
            return {
                confirm: false,
                loading: false,
                activeCardCount: 0,
                level: {},
            }
        },
        computed: {
            userCompany(){
                return this.$store.state.userCompany;
            },
            isSuspended(){
                let curUser = Parse.User.current();
                let compData = this.$store.state.userCompany.attributes;

                if((curUser && curUser.attributes.suspended) || (compData && compData.suspended)){
                    return true;
                }else {
                    return false;
                }
            },

            billableEstimate(){
                return this.activeCardCount && this.activeLevel ? this.activeCardCount * this.activeLevel.price : 1.00;
            },

            currentCardCount(){
                return this.$store.state.userCompany.attributes.cardCount ? this.$store.state.userCompany.attributes.cardCount : 0;
            },

            trialXPNiceDate() {
                let curUser = Parse.User.current();
                let userTrial = curUser && curUser.attributes.trialUntil ? moment(curUser.attributes.trialUntil) : null;
                let companyTrial = this.userCompany && this.userCompany.attributes.trialUntil ? moment(this.userCompany.attributes.trialUntil) : null;

                let maxTrial = null;

                // Check if both dates are valid
                if (userTrial && userTrial.isValid() && companyTrial && companyTrial.isValid()) {
                    maxTrial = moment.max(userTrial, companyTrial);
                } else if (userTrial && userTrial.isValid()) {
                    // Only user trial date is valid
                    maxTrial = userTrial;
                } else if (companyTrial && companyTrial.isValid()) {
                    // Only company trial date is valid
                    maxTrial = companyTrial;
                }

                return maxTrial ? maxTrial.format('D/MM/Y') : false;
            },

            nextBillingDate() {
                const today = moment();
                const thirdOfThisMonth = moment().startOf('month').add(2, 'days'); // 3rd of current month

                if (today.isBefore(thirdOfThisMonth)) {
                    // If today's date is before the 3rd of the current month
                    return thirdOfThisMonth.format('D/M/YYYY');
                } else {
                    // If today's date is on or after the 3rd of the current month
                    const thirdOfNextMonth = thirdOfThisMonth.add(1, 'month');
                    return thirdOfNextMonth.format('D/M/YYYY');
                }
            }

        },

        methods: {
            backToDashboard(){
                this.$router.push({name: 'home'});
            },
            setActiveCardCount(count){
                this.activeCardCount = count;
            },
            setActiveLevel(level){
                this.activeLevel = level;
            },
            openSubscribe(){

                let ulr_base = 'https://www.qfecard.com/subscribe';
                let curUser = Parse.User.current();
                let compData = this.$store.state.userCompany.attributes;

                let params = {
                    email: curUser.attributes.email,
                    firstName: curUser.attributes.firstName,
                    lastName: curUser.attributes.lastName,
                    company: compData.name,
                    phone: compData.telephone,
                    address: compData.billingAddress,
                    billingEmail: compData.billingEmail,
                    vat: compData.vatNumber,
                    responsible: compData.responsible,
                    website: compData.website,
                    companyId: this.$store.state.userCompany.id,
                }

                if(compData.usedPromo && compData.usedPromo.get('code')){
                    params.promo = compData.usedPromo.attributes.code;
                }

                let paramsString = JSON.stringify(params);
                let encodedParams = btoa(paramsString);

                let url = ulr_base +'?data='+ encodedParams;

                window.open(url, '_blank');

            },
            confirmSubscription(){
                this.$store.commit('setGlobalLoader', true);

                this.$store.dispatch('confirmSubscription').then(()=>{
                    this.$router.push({name: 'home'});
                    this.$store.commit('setGlobalLoader', false);
                });
            },
            logout() {
                this.$store.dispatch('logout').then(() => {
                    this.$store.commit('setIsAuthenticated', false);
                    this.$store.commit('setUser', null);
                    this.$router.push('/auth');
                });
            },
        }
    }
</script>

<style scoped>
    .header-row {
        position: relative;
    }
    .eco-log-out-control {
        position: absolute;
        top: 12px;
        right: 10px;
        padding: 10px;
        cursor: pointer;
    }

    .auth-form.wide {
        max-width: 720px;
    }

    .container {
        min-height: 100vh;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .welcome-separator {
        width: 100%;
        border-top: 1px solid var(--color-secondary);
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .space {
        width: 100%;
        background: transparent;
        opacity: 0;
        width: 100%;
        height: 10px;
    }

    .welcome-message {
        font-size: 0.8em;
    }

    .form-loader, .form-fields {
        min-height: 290px;
    }

    .invitation-loading {
        width: 100%;
        text-align: center;
        padding-top: 100px;
    }

    .company-logo{
        object-fit: cover;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid #000000;
    }
</style>
