import Parse from 'parse'
import Api from './Api'

export const UserService = {
    login(context, data) {

        return new Promise((resolve, reject) => {

            Parse.User.logIn(data.username, data.password).then((user)=>{
                resolve(user);
            }).catch((err)=> {
                reject(err);
            })

        });
    },
    logout(context) {
        context.commit('resetState', true);
        return Parse.User.logOut();
    },
    currentUser(context) {
        context.commit('setGlobalLoader', true);
        return new Promise((resolve, reject) => {
            Parse.User.currentAsync().then(response => {
                context.commit('setIsAuthenticated', response !== null);
                context.commit('setUser', response);
                context.commit('setGlobalLoader', false);
                resolve(response);
            }).catch(err => {
                reject(err);
            });
        })
    },
    changePassword(context, password) {
        context.state.user.setPassword(password);
        return new Promise((resolve, reject) => {
            context.state.user.save().then(response => {
                resolve(response);
            }).catch(err => {
                reject(err);
            });
        });
    },
    changeInformation(context, data) {
        for (const key in data.form) {
            context.state.user.set(key, data.form[key]);
        }
        if (data.avatar !== undefined) {
            let file = new Parse.File(data.avatar.name, data.avatar.file);
            context.state.user.set('avatar', file);
        }
        return new Promise((resolve, reject) => {
            context.state.user.save().then(response => {
                context.commit('setUser', null);
                context.commit('setUser', response);
                resolve(response);
            }).catch(err => {
                reject(err);
            });
        });
    },
    getOnboardData(context, data){
        let formData = new FormData();
        formData.append('action', 'getOnboardData');
        formData.append('data', JSON.stringify(data));
        return new Promise((resolve, reject) => {
            Api().post('helpers/api.php', formData).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            });
        });

    },
    onboardUser(context, data){
        let formData = new FormData();
        formData.append('action', 'onboardUser');
        formData.append('data', JSON.stringify(data));
        return new Promise((resolve, reject) => {
            Api().post('helpers/api.php', formData).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    passwordReset(context, data) {
        let formData = new FormData();
        formData.append('action', 'passwordReset');
        //formData.append('sessionToken', context.state.user.attributes.sessionToken);
        formData.append('data', JSON.stringify(data));
        return new Promise((resolve, reject) => {
            Api().post('helpers/api.php', formData).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            });
        });
    },

    requestTrial(context, data) {
        let formData = new FormData();
        formData.append('action', 'requestTrial');
        //formData.append('sessionToken', context.state.user.attributes.sessionToken);
        formData.append('data', JSON.stringify(data));
        return new Promise((resolve, reject) => {
            Api().post('helpers/api.php', formData).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            });
        });
    },

    checkPromo(context, data){
        let formData = new FormData();
        formData.append('action', 'checkPromoCode');
        formData.append('data', JSON.stringify(data));

        return new Promise((resolve, reject) => {
            Api().post('helpers/api.php', formData).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            });
        });
    },

    saveEmployeeCards(context, data) {

        let addrObject = { "__type": "Pointer", "className": "OfficeAddress", "objectId": data.officeAddress.id }

        if (data.id === null) {
            let employeeCard = Parse.Object.extend('Employee');
            let employeeCardRecord = new employeeCard();
            return new Promise((resolve, reject) => {
                employeeCardRecord.set('firstName', data.firstName);
                employeeCardRecord.set('lastName', data.lastName);
                employeeCardRecord.set('position', data.position);
                employeeCardRecord.set('department', data.department);
                employeeCardRecord.set('mobilePhone', data.mobilePhone);
                employeeCardRecord.set('officePhone', data.officePhone);
                employeeCardRecord.set('email', data.email);
                employeeCardRecord.set('cardToken', data.randString);
                employeeCardRecord.set('officeAddress', addrObject);
                employeeCardRecord.set('company', context.state.cardCompany);
                employeeCardRecord.save().then(response => {
                    context.dispatch('getEmployeeCards');
                    resolve(response);
                }).catch(err => {
                    reject(err);
                })
            })
        } else {
            return new Promise((resolve, reject) => {
                context.getters.selectedModalData('employeeCards').set('firstName', data.firstName);
                context.getters.selectedModalData('employeeCards').set('lastName', data.lastName);
                context.getters.selectedModalData('employeeCards').set('position', data.position);
                context.getters.selectedModalData('employeeCards').set('department', data.department);
                context.getters.selectedModalData('employeeCards').set('mobilePhone', data.mobilePhone);
                context.getters.selectedModalData('employeeCards').set('officePhone', data.officePhone);
                context.getters.selectedModalData('employeeCards').set('email', data.email);
                //context.getters.selectedModalData('employeeCards').set('address', data.address);
                context.getters.selectedModalData('employeeCards').set('officeAddress', addrObject);
                context.getters.selectedModalData('employeeCards').set('company', context.state.cardCompany);
                context.getters.selectedModalData('employeeCards').set('cardVer', Number(data.cardVer)+1);
                context.getters.selectedModalData('employeeCards').save().then(response => {
                    context.dispatch('getEmployeeCards');
                    resolve(response);
                }).catch(err => {
                    reject(err);
                })
            })
        }
    },
    saveUser2(context, data){
        let curUser = Parse.User.current();

        let user = new Parse.User();
        if(data.id) {
            user.set("objectId", data.id);
            user.set("passwordReset", data.randString);
        }

        user.set("username", data.username);
        user.set("email", data.email);
        user.set("password", data.password);

        user.set("firstName", data.firstName);
        user.set("lastName", data.lastName);

        if(data.company.id) {
            let compPointer = { "__type": "Pointer", "className": "Company", "objectId": data.company.id }
            user.set("company", compPointer);
        }

        if (data.avatar !== undefined && data.avatar.file) {
            let file = new Parse.File(data.avatar.name, data.avatar.file);
            user.set('avatar', file);
        }

        return new Promise((resolve, reject) => {
            if(data.id) {
                user.save().then(function(response){
                    resolve(response);
                }).catch(function(err){
                    reject(err);
                });
            } else {
                user.setWriteAccess(curUser.id, true);
                user.signUp().then(function(response){
                    resolve(response);
                    //self.setDefaults(resp.id);
                    //self.login();
                }).catch(function(err){
                    reject(err);
                });
            }
        });
        /*
            formData.append('action', 'updateUser');
            //formData.append('sessionToken', context.state.user.attributes.sessionToken);
            formData.append('data', JSON.stringify(data));
            return new Promise((resolve, reject) => {
                Api().post('helpers/api.php', formData).then(response => {
                    resolve(response.data);
                }).catch(err => {
                    reject(err);
                });
            });

            userToSave.set('firstName', newFields.firstName);
            userToSave.set('lastName', newFields.lastName);
        */
    },

    saveUser(context, data) {
        let formData = new FormData();

        if(data.id) {
            formData.append('action', 'editUser');
        } else {
            formData.append('action', 'createUser');
        }
        formData.append('sessionToken', context.state.user.attributes.sessionToken);

        formData.append('data', JSON.stringify(data));

        return new Promise((resolve, reject) => {
            Api().post('helpers/api.php', formData).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            });
        });
    },

    deleteUser(context){
        let usersToDelete = context.getters.selectedModalData('visibleUsers');

        let data = {
            userIds: []
        };

        usersToDelete.forEach(function(element){
            data.userIds.push(element.id);
        });

        let formData = new FormData();

        formData.append('action', 'deleteUser');
        formData.append('sessionToken', context.state.user.attributes.sessionToken);
        formData.append('data', JSON.stringify(data));

        return new Promise((resolve, reject) => {
            Api().post('helpers/api.php', formData).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            });
        });

    },
    getUserEmail(context, data) {
        let formData = new FormData();
        formData.append('action', 'getUserMail');
        formData.append('data', JSON.stringify(data));
        formData.append('sessionToken', context.state.user.attributes.sessionToken);
        return new Promise((resolve, reject) => {
            Api().post('helpers/api.php', formData).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    changePasswordApi(context, data) {
        let formData = new FormData();
        formData.append('action', 'setNewPassword');
        //formData.append('sessionToken', context.state.user.attributes.sessionToken);
        formData.append('data', JSON.stringify(data));
        return new Promise((resolve, reject) => {
            Api().post('helpers/api.php', formData).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            });
        });
    }
};
