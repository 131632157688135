<template>
    <div class="eco-content-space h100 overflow-y-auto">
        <div class="white-box">
            <div class="table-top">

                <div class="table-top-buttons">
                    <button-field @click="addNewClient()"
                                  icon="plus"
                                  color="primary">{{$ml.get('ADD_CLIENT')}}
                    </button-field>
                </div>


                <div class="search-container">
                    <font-awesome-icon icon="search"/>
                    <input type="text" maxlength="20" name="searchInput"
                           v-model="searchString"
                           class="search-input form-field"
                           :placeholder="$ml.get('search_by_name_vat_website')"
                    />
                </div>

            </div>


            <div class="eco-content-scroll">
                <div class="table-list-wrapper">
                    <table class="table-list">
                        <thead class="font-bebas-neue">
                            <tr>
                                <th></th>
                                <th>{{$ml.get('AGENCY_NAME')}}</th>
                                <th>{{$ml.get('CREATED_ON')}}</th>
                                <th>{{$ml.get('RESPONSIBLE')}}</th>
                                <th>{{$ml.get('BILLING_EMAIL')}}</th>
                                <th>{{$ml.get('STATUS')}}</th>
                                <th>{{$ml.get('NUMBER_OF_CLIENTS')}}</th>
                                <th>{{$ml.get('SUSPENDED_CLIENTS')}}</th>
                                <th>{{$ml.get('TOTAL_CARDS')}}</th>
                                <th class="actions">{{$ml.get('ACTIONS')}}</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="(agency, index) in agenciesWithComputedCardCounts" v-bind:key="'agency-'+index" :class="suspendedRow(agency)">
                                <td v-if="agency.attributes.isAgency" class="hasCursor" @click="goToClientCards(agency)">
                                    <a href="#">
                                        <div class="ellipsis">
                                            <img v-if="agency.attributes.logo"
                                                 :src="agency.attributes.logo._url" class="company-logo"/>
                                        </div>
                                    </a>
                                </td>
                                <td v-else class="hasCursor" @click="showAgencyClients(agency)">
                                    <div class="ellipsis">
                                        <img v-if="agency.attributes.logo" :src="agency.attributes.logo._url" class="company-logo"/>
                                    </div>
                                </td>
                                <td class="td-elipsis-150 hasCursor" @click="showAgencyClients(agency)">
                                    <a href="#">
                                        {{agency.attributes.name}}
                                    </a>
                                </td>
                                <td class="td-elipsis">
                                    {{agency.attributes.createdAt | dateFormat}}
                                </td>

                                <td class="td-elipsis">
                                    {{agency.attributes.responsible}}
                                </td>
                                <td>
                                    <div class="ellipsis">{{agency.attributes.billingEmail}}</div>
                                </td>
                                <td>
                                    <div class="ellipsis" :class="agency.attributes.verified ? 'text-secondary' : 'text-primary'">
                                        {{agency.attributes.verified ? $ml.get(`Verified`) : $ml.get(`Not_verified`) }}
                                    </div>
                                    <div v-if="agency.attributes.verified && agency.attributes.verifiedOn" class="trial-subscript text-secondary">
                                        <span>{{$ml.get(`Verified_on`)}}: {{agency.attributes.verifiedOn | dateFormat}}</span>
                                    </div>
                                </td>
                                <td>
                                    <div class="ellipsis">{{agency.attributes.relatedCompanies ? agency.attributes.relatedCompanies.length : 0}}</div>
                                </td>
                                <td>
                                    <div class="ellipsis">{{calculateSuspendedClients(agency)}}</div>
                                </td>
                                <td>
                                    <div class="ellipsis">{{calculateAgencyCards(agency)}}</div>
                                </td>

                                <td>
                                    <div class="table-action-btn-gorup">
                                        <button-field
                                            v-if="isAdmin"
                                            circle
                                            outline
                                            @click="editAgencySettings(agency.id)"
                                            v-tooltip="$ml.get('tooltip_agency_settings')"
                                            icon="drafting-compass"
                                            class="table-action-btn"
                                            color="dark"></button-field>
                                        <button-field :disabled="sharing"
                                                      circle
                                                      outline
                                                      @click="copySelfOnboardLink(agency)"
                                                      v-tooltip="$ml.get('tooltip_onboard_link')"
                                                      icon="link"
                                                      class="table-action-btn"
                                                      color="secondary"></button-field>
                                        <button-field @click="editClient(agency.id)"
                                                      circle
                                                      outline
                                                      v-tooltip="`Edit client`"
                                                      icon="edit"
                                                      class="table-action-btn"
                                                      color="primary"></button-field>
                                        <button-field @click="suspendClient(agency.id)"
                                                      circle
                                                      outline
                                                      :activated="isSuspended(agency)"
                                                      v-tooltip="suspendMesssage(agency)"
                                                      icon="moon"
                                                      color="dark"
                                                      class="table-action-btn"></button-field>
                                        <button-field @click="deleteClient(agency.id)"
                                                      circle
                                                      outline
                                                      v-tooltip="`Delete agency`"
                                                      icon="trash-alt"
                                                      class="table-action-btn"
                                                      :disabled="forbidAgencyDelete(agency)"
                                                      color="error"></button-field>
                                    </div>
                                </td>

                            </tr>
                        </tbody>

                    </table>

                </div>

            </div>
            <div class="table-bottom">
                <div class="table-bottom-buttons">

                    <div class="d-inline-block ml-2">
                        <checkbox-field id="showSuspended"
                                        name="showSuspended"
                                        color="secondary"
                                        v-model="showSuspended"
                        >{{$ml.get('show_suspended')}}</checkbox-field>
                    </div>
                </div>

                <div class="table-result-per-page">
                    <select-field :name="`selectField`"
                                  :id="`selectField`"
                                  :options="perPageOptions"
                                  :value="perPageValue"
                                  v-model="perPageValue"
                                  class="per-page-select">{{$ml.get('RESULTS_PER_PAGE')}}
                    </select-field>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import CheckboxField from '@/components/global/CheckboxField';

    export default {
        name: "DashboardAgencies",
        components: {
            CheckboxField
        },
        computed: {
            masterAgencies() {
                let rows = this.$store.state.masterAgencies;
                let valid = rows.filter((item) => {
                    return !item.attributes.core;
                });
                return valid;
            },
            agenciesWithComputedCardCounts() {
                return this.masterAgencies.map((agency) => {
                    let total = agency.attributes.currentCardCount || 0;

                    if (agency.attributes.relatedCompanies && agency.attributes.relatedCompanies.length > 0) {
                        agency.attributes.relatedCompanies.forEach((client) => {
                            if (client.attributes && client.attributes.currentCardCount && !client.attributes.suspended) {
                                total += client.attributes.currentCardCount;
                            }
                        });
                    }

                    // Attach the computed total directly to the agency object, or handle it as needed
                    agency.computedCardCount = total;
                    return agency;
                });
            },
            userCompany() {
                return this.$store.state.userCompany;
            },
            isAdmin() {
                return this.$store.state.user.attributes.isAdmin;
            },
            tableRows() {
                let rows = this.$store.state.agencyClients;
                if( this.$store.state.userCompany
                    && this.$store.state.userCompany.attributes.isAgency
                    && !this.$store.state.userCompany.attributes.core
                ){
                    rows.push(this.$store.state.userCompany)
                }

                let suspended = rows.filter((item) => {
                    return item.attributes.suspended;
                });

                let active = rows.filter((item) => {
                    return !item.attributes.suspended;
                });

                return this.showSuspended ? suspended : active;
            },
            selectedRowsTrue() {
                let selected = [];
                for (const key in this.selectedRows) {
                    if (this.selectedRows[key]) {
                        selected.push(key);
                    }
                }
                return selected;
            }
        },
        data() {
            return {
                selectedAgency: null,
                perPageOptions: [
                    {
                        value: 10,
                        text: 10
                    },
                    {
                        value: 25,
                        text: 25
                    },
                    {
                        value: 50,
                        text: 50
                    },
                    {
                        value: 100,
                        text: 100
                    },
                ],
                perPageValue: 25,
                selectedRows: {},
                sharing: false,
                wizzardOpened: true,
                searchString: null,
                timeout: null,
                showSuspended: false,

            }

        },
        created() {
            this.$store.dispatch('getCompany').then((userCompany) => {
                    if(userCompany.attributes.core){
                        this.$store.dispatch('getAllAgencies')
                    }
            });
            this.$store.commit('closeRightModal');
        },
        methods: {
            showClients(item){
                this.selectedAgency = item;
                this.$store.dispatch('getAgencyClients', {clientOf:item})
            },
            calculateCards(item) {
                return item.attributes.currentCardCount ? item.attributes.currentCardCount : 0;
            },
            calculateSuspendedClients(item){
                let total = 0;
                if(item.attributes.relatedCompanies && item.attributes.relatedCompanies.length > 0){
                    item.attributes.relatedCompanies.forEach((client) => {
                        if(client.attributes && client.attributes.suspended){
                            total++;
                        }
                    })
                }
                return total;
            },
            calculateAgencyCards(item){
                let total = item.attributes.currentCardCount ? item.attributes.currentCardCount : 0;
                console.log("Agency related companies", item.attributes.relatedCompanies);

                if(item.attributes.relatedCompanies && item.attributes.relatedCompanies.length > 0){
                    item.attributes.relatedCompanies.forEach((client) => {
                        if(client.attributes && client.attributes.currentCardCount && !client.attributes.suspended){
                            total += client.attributes.currentCardCount;
                        }
                    })
                }
                return total;
            },
            clearSelectedAgency(){
                this.selectedAgency = null;
                this.checkingAgencies = true;
                this.$store.dispatch('getAllAgencies')
            },
            computeStatus(item){
                let output = {
                    text: '',
                    color: '',
                    icon: 'check'
                };

                let company = item;
                let cardCount = company.attributes.currentCardCount;
                let users = company.attributes.relatedUsers ? company.attributes.relatedUsers : [];
                let cardConfig = company.attributes.relatedCardConfig ? company.attributes.relatedCardConfig : null;

                let hasDesign = (cardConfig && cardConfig.attributes.designVer > 1);
                let hasUsers = (users.length > 0);
                let hasCards = (cardCount > 0);

                if(!hasCards && !hasUsers &&  !hasDesign){
                    output.text = 'New';
                    output.color = 'blue';
                    output.icon = 'star';
                }else if(!hasDesign){
                    output.text = 'Missing design';
                    output.color = 'primary';
                    output.icon = 'palette';

                } else if (!hasUsers) {
                    output.text = 'No user assigned';
                    output.color = 'primary';
                    output.icon = 'user';
                } else if (!hasCards) {
                    output.text = 'Ready to onboard';
                    output.color = 'dark';
                    output.icon = 'calendar';
                } else {
                    output.text = 'Active';
                    output.color = 'secondary';
                    output.icon = 'check';
                }

                return output;
            },

            showAgencyClients(item){
                this.selectedAgency = item;
                this.$router.push({name: 'clients', params: {agencyId: item.id}});
            },

            extractPromo(item) {
                if (item.attributes.usedPromo
                    && item.attributes.usedPromo.attributes
                    && item.attributes.usedPromo.attributes.code
                ) {
                    return item.attributes.usedPromo.attributes.code;
                } else {
                    return '';
                }
            },

            doSearch(searchString){
                this.$store.dispatch('getAgencyClients', {searchTerm : searchString})
            },
            suspendedRow(item){
                if(this.isSuspended(item)){
                    return 'suspended';
                }else{
                    return '';
                }
            },
            copySelfOnboardLink(item){

                let invitationLink = window.location.protocol + '//' + window.location.host
                    +'/addme/'+item.id+'/'+item.attributes.frameSecret;

                navigator.clipboard.writeText(invitationLink);

                this.$notify({
                    group: 'notification',
                    type: 'success',
                    text: this.$ml.get('notification_onboard_link_copied')
                });

            },
            suspendMesssage(item){
                if(this.isSuspended(item)){
                    return this.$ml.get('RESTORE_CLIENT');
                }else {
                    return this.$ml.get('SUSPEND_CLIENT');
                }
            },
            isSuspended(item){
                return item.attributes.suspended;
            },
            suspendClient(id){
                this.$store.commit('toggleDashboardModal', {name: 'DashboardClientSuspend', data: {ids: [id], dataSet: 'masterAgencies'}})
            },
            forbidDelete(item){
                return item.attributes.isAgency;
            },
            forbidAgencyDelete(item){
                return item.attributes.relatedCompanies.length > 0;
            },
            toggleModal(componentName) {
                this.$store.commit('toggleDashboardModal', {name: componentName})
            },
            addNewClient(){
                this.$router.push({name: 'client-add'});
            },
            editAgencySettings(id = null) {
                this.$store.commit('toggleDashboardModal', {name: 'DashboardAgencySettingsForm', data: {id: id, dataSet: 'masterAgencies'}})
            },
            editClient(id = null) {
                this.$store.commit('toggleDashboardModal', {name: 'DashboardClientsForm', data: {id: id, dataSet: 'masterAgencies'}})
                this.wizzardOpened = true;
            },
            deleteClient(id, isAgency = false) {
                // DashboardEmployeeCardsDelete
                this.$store.commit('toggleDashboardModal', {name: 'DashboardClientsDelete', data: {ids: [id], isAgency: isAgency}})
            },
            goToClientCards(clientCompany) {
                let self = this;
                this.$store.dispatch('changeImpersonation', clientCompany).then(function(response){
                    if(response.attributes.impersonating){
                        self.$notify({
                            group: 'notification',
                            type: 'success',
                            text: "Switching to managing "+clientCompany.attributes.name+"...",
                        });
                        self.$store.commit('setCardCompany',clientCompany);
                        setTimeout(function(){
                            self.$router.push({name: 'home'});
                        }, 1500);
                    }else {
                        self.$notify({
                            group: 'notification',
                            type: 'error',
                            title: self.$ml.get('notification_error_title'),
                            text: self.$ml.get('notification_error_general'),
                        });
                    }
                });

                // if (!this.sharing) {
                //     this.sharing = true;
                //     this.$store.dispatch('shareCards', ids).then(() => {
                //         this.$notify({
                //             group: 'notification',
                //             type: 'success',
                //             text: this.$ml.get('notification_share_card'),
                //         });
                //         this.sharing = false;
                //     }).catch((e) => {
                //         this.$notify({
                //             group: 'notification',
                //             type: 'error',
                //             title: this.$ml.get('notification_error_title'),
                //             text: this.$ml.get('notification_error_general'),
                //         });
                //         this.sharing = false;
                //         self.$checkTokenExp(e);
                //     })
                // }
            },
        },
        watch : {
            // masterAgencies(newVal){
            //     console.log('masterAgencies', newVal);
            // },
            searchString(newSearch) {
                clearTimeout(this.timeout);
                let self = this;
                self.timeout = setTimeout(() => {
                    self.doSearch(newSearch);
                }, 300);
            },
        }
    }
</script>

<style scoped>
    .per-page-select {
        width: 110px;
    }
    .hasCursor {
        cursor: pointer;
    }
    .suspended {
        opacity: 0.5;
    }

    .selected-agency-preview {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
    }

    .company-logo {
        max-height: 16px;
        max-width: 40px;
    }

</style>
