<template>
    <transition name="fade">
        <div class="trial-stripe" v-if="trialOpen">
            <div class="notice-middle">

                <span class="notice-text mr-2" v-if="hasEverSubscribed">Your subscription is active only until {{nextBillingDate}}! </span>
                <span class="notice-text mr-2" v-else-if="trialDaysRemaining > 0">Your account is in trial period. You currently have <strong>{{trialDaysRemaining}} day(s)</strong> remaining. </span>
                <span class="notice-text mr-2" v-else-if="trialDaysRemaining == 0">Your trial is expiring <strong>today!</strong>. Click the button on the right to avoid account suspesion. </span>
                <span class="notice-text mr-2" v-else-if="trialDaysRemaining < 0">Your account trial has expired <strong>{{-1*trialDaysRemaining}} day(s) ago!</strong></span>
                <button-field v-if="hasEverSubscribed"
                              @click="openSubscribe"
                              color="dark" variant="primary"
                              :icon="`check`">Reactivate Subscription</button-field>
                <button-field v-else
                              @click="openSubscribe"
                              color="dark" variant="primary"
                              :icon="`check`">Activate Subscription</button-field>
            </div>
            <div class="notice-close_control"><font-awesome-icon icon="times" @click="closeTrialNotice"></font-awesome-icon></div>
        </div>
    </transition>
</template>

<script>

import Parse from "parse";
import moment from "moment/moment";

export default {
    data() {
        return {
        trialNoticeOpen: true
        }
    },
    computed: {
        nextBillingDate() {
            const today = moment();
            const thirdOfThisMonth = moment().startOf('month').add(2, 'days'); // 3rd of current month

            if (today.isBefore(thirdOfThisMonth)) {
                // If today's date is before the 3rd of the current month
                return thirdOfThisMonth.format('D/M/YYYY');
            } else {
                // If today's date is on or after the 3rd of the current month
                const thirdOfNextMonth = thirdOfThisMonth.add(1, 'month');
                return thirdOfNextMonth.format('D/M/YYYY');
            }
        },
        curUser(){
            return Parse.User.current();
        },
        userCompany(){
            return this.$store.state.userCompany;
        },
        curRoute(){
          return this.$route.name;
        },

        trialOpen() {
            let userTrial = this.curUser ? this.curUser.attributes.trialUntil : false;
            let companyTrial = this.userCompany ? this.userCompany.attributes.trialUntil : false;
            //debugger;

            if(this.trialNoticeOpen &&
                (userTrial || companyTrial)
                && this.curRoute !== 'suspended'
                && this.curRoute !== 'profile'
                && this.curRoute !== 'terms-regular'
                && this.curRoute !== 'terms-agency'
            ) {
                return true;
            } else  {
                return false;
            }
        },

        trialDaysRemaining() {
            let userTrial = this.curUser && this.curUser.attributes.trialUntil ? moment(this.curUser.attributes.trialUntil) : null;
            let companyTrial = this.userCompany && this.userCompany.attributes.trialUntil ? moment(this.userCompany.attributes.trialUntil) : null;

            let maxTrial = null;

            // Check if both dates are valid
            if (userTrial && userTrial.isValid() && companyTrial && companyTrial.isValid()) {
                maxTrial = moment.max(userTrial, companyTrial);
            } else if (userTrial && userTrial.isValid()) {
                // Only user trial date is valid
                maxTrial = userTrial;
            } else if (companyTrial && companyTrial.isValid()) {
                // Only company trial date is valid
                maxTrial = companyTrial;
            }

            if (maxTrial) {
                let remainingTrial = maxTrial.diff(moment(), 'days');
                return remainingTrial;
            } else {
                return 0;
            }
        },

        hasTrial() {
            let hasTrial = this.curUser ? this.curUser.attributes.trialUntil : false;

            if(hasTrial) {
                return true;
            } else  {
                return false;
            }
        },
    },
    methods: {
        closeTrialNotice(){
            this.trialNoticeOpen = false;
        },

        hasEverSubscribed(){
            let hasSubscribed = this.userCompany ? this.userCompany.attributes.subscribedOn : false;
            return hasSubscribed;
        },

        openSubscribe(){
               this.$router.push({name: 'profile'});
        },
    },
    watch: {
        // trialDaysRemaining(trial) {
        //     //debugger;
        //     if(trial <= 0) {
        //         this.trialNoticeOpen = true;
        //         console.log("Current route is: ", this.$route);
        //         if(this.curRoute !== 'suspended'
        //             && this.curRoute !== 'profile'
        //             && this.curRoute !== 'terms-regular'
        //             && this.curRoute !== 'terms-agency') {
        //             this.$router.push({name: 'suspended'});
        //         }
        //     }
        // }
    }
}
</script>

<style>

</style>
