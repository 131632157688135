<template>
    <div class="container">
        <div class="auth-form">
            <div class="row header-row">
                <div class="col text-center login-logo-row">
                    <img src="@/assets/qfecard-logo-white.svg" alt="logo">
                </div>
                <div class="eco-log-out-control">
                    <font-awesome-icon
                        icon="sign-out-alt"
                        class="text-white cursor-pointer"
                        @click="logout"
                        v-tooltip="$ml.get('SIGN_OUT')"
                        size="lg"></font-awesome-icon>
                </div>
            </div>

            <div class="form-fields">
                <div id="link-invalid" class="text-center">
                    <h3 class="my-0"><font-awesome-icon  icon="times" size="xl"></font-awesome-icon> You are not authorised to view this page!</h3>

                    <div class="space"></div>
                    <div class="welcome-separator"></div>

                    <div class="mt-1">

                        <p class="text-center py-3 text-primary "><font-awesome-icon class="text-lg-center" :icon="['fas', 'exclamation-triangle']" ></font-awesome-icon></p>
                        <p class="text-center">It appears that you have arrived on this page by mistake.</p>
                        <p class="text-center">If you think this has happened in error, please contact the administrator who had on-boarded you.</p>
                        <hr/>
                        <div class="row justify-content-center mb-3">
                            <div class="col-4">
                                <button-field color="secondary"
                                              type="submit"
                                              block
                                              @click="backToDashboard"
                                              icon="home"
                                >
                                    {{$ml.get('BACK_TO_DASHBOARD')}}
                                </button-field>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    import Parse from "parse";

    import {VBTooltip} from "bootstrap-vue";

    export default {
        name: "NotAuthorised",
        components: {
        },
        directives: {
            'b-tooltip': VBTooltip
        },
        data() {
            return {
                confirm: false,
                loading: false,
                activeCardCount: 0,
                level: {},
            }
        },
        computed: {
            userCompany(){
                return this.$store.state.userCompany;
            },
            isSuspended(){
                let curUser = Parse.User.current();
                let compData = this.$store.state.userCompany.attributes;

                if((curUser && curUser.attributes.suspended) || (compData && compData.suspended)){
                    return true;
                }else {
                    return false;
                }
            },

            billableEstimate(){
                return this.activeCardCount && this.activeLevel ? this.activeCardCount * this.activeLevel.price : 1.00;
            },

            currentCardCount(){
                return this.$store.state.userCompany.attributes.cardCount ? this.$store.state.userCompany.attributes.cardCount : 0;
            },

            trialXPNiceDate() {
                let curUser = Parse.User.current();
                let userTrial = curUser && curUser.attributes.trialUntil ? moment(curUser.attributes.trialUntil) : null;
                let companyTrial = this.userCompany && this.userCompany.attributes.trialUntil ? moment(this.userCompany.attributes.trialUntil) : null;

                let maxTrial = null;

                // Check if both dates are valid
                if (userTrial && userTrial.isValid() && companyTrial && companyTrial.isValid()) {
                    maxTrial = moment.max(userTrial, companyTrial);
                } else if (userTrial && userTrial.isValid()) {
                    // Only user trial date is valid
                    maxTrial = userTrial;
                } else if (companyTrial && companyTrial.isValid()) {
                    // Only company trial date is valid
                    maxTrial = companyTrial;
                }

                return maxTrial ? maxTrial.format('D/MM/Y') : false;
            },

            nextBillingDate() {
                const today = moment();
                const thirdOfThisMonth = moment().startOf('month').add(2, 'days'); // 3rd of current month

                if (today.isBefore(thirdOfThisMonth)) {
                    // If today's date is before the 3rd of the current month
                    return thirdOfThisMonth.format('D/M/YYYY');
                } else {
                    // If today's date is on or after the 3rd of the current month
                    const thirdOfNextMonth = thirdOfThisMonth.add(1, 'month');
                    return thirdOfNextMonth.format('D/M/YYYY');
                }
            }

        },

        methods: {
            backToDashboard(){
                this.$router.push({name: 'home'});
            },
            setActiveCardCount(count){
                this.activeCardCount = count;
            },
            setActiveLevel(level){
                this.activeLevel = level;
            },
            openSubscribe(){

                let ulr_base = 'https://www.qfecard.com/subscribe';
                let curUser = Parse.User.current();
                let compData = this.$store.state.userCompany.attributes;

                let params = {
                    email: curUser.attributes.email,
                    firstName: curUser.attributes.firstName,
                    lastName: curUser.attributes.lastName,
                    company: compData.name,
                    phone: compData.telephone,
                    address: compData.billingAddress,
                    billingEmail: compData.billingEmail,
                    vat: compData.vatNumber,
                    responsible: compData.responsible,
                    website: compData.website,
                    companyId: this.$store.state.userCompany.id,
                }

                if(compData.usedPromo && compData.usedPromo.get('code')){
                    params.promo = compData.usedPromo.attributes.code;
                }

                let paramsString = JSON.stringify(params);
                let encodedParams = btoa(paramsString);

                let url = ulr_base +'?data='+ encodedParams;

                window.open(url, '_blank');

            },
            confirmSubscription(){
                this.$store.commit('setGlobalLoader', true);

                this.$store.dispatch('confirmSubscription').then(()=>{
                    this.$router.push({name: 'home'});
                    this.$store.commit('setGlobalLoader', false);
                });
            },
            logout() {
                this.$store.dispatch('logout').then(() => {
                    this.$store.commit('setIsAuthenticated', false);
                    this.$store.commit('setUser', null);
                    this.$router.push('/auth');
                });
            },
        }
    }
</script>

<style scoped>
.text-lg-center {
    font-size: 64px;
}
    .header-row {
        position: relative;
    }
    .eco-log-out-control {
        position: absolute;
        top: 12px;
        right: 10px;
        padding: 10px;
        cursor: pointer;
    }

    .auth-form.wide {
        max-width: 720px;
    }

    .container {
        min-height: 100vh;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .welcome-separator {
        width: 100%;
        border-top: 1px solid var(--color-secondary);
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .space {
        width: 100%;
        background: transparent;
        opacity: 0;
        width: 100%;
        height: 10px;
    }

    .welcome-message {
        font-size: 0.8em;
    }

    .form-loader, .form-fields {
        min-height: 290px;
    }

    .invitation-loading {
        width: 100%;
        text-align: center;
        padding-top: 100px;
    }

    .company-logo{
        object-fit: cover;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid #000000;
    }
</style>
