<template>
    <div class="eco-content-space h100">
        <div class="employee-cards h100">
            <div class="table-top">
                <div class="table-top-buttons">
                    <button-field @click="editEmployee()"
                                  icon="plus"
                                  color="primary">{{$ml.get('ADD_EMPLOYEE')}}
                    </button-field>
                    <button-field @click="toggleModal('DashboardEmployeeCardsImport')"
                                  icon="file-import"
                                  color="secondary">{{$ml.get('IMPORT_FROM_CSV')}}
                    </button-field>
                </div>
                <div class="table-count">
                    <span class="card-count">{{$ml.get('TOTAL_CARD_COUNT')}}: {{resultsFrom}} - {{resultsTo}} / {{totalResults}}</span>
                </div>
                <div class="search-container">
                    <font-awesome-icon icon="search"/>
                    <input type="text" maxlength="20" name="searchInput"
                           v-model="searchString"
                           class="search-input form-field"
                           :placeholder="$ml.get('search_by_name')"
                    />
                </div>
            </div>
            <div class="eco-content-scroll">
                <div class="table-list-wrapper">
                    <table class="table-list">
                        <thead class="font-bebas-neue">
                            <tr>
                                <th>
                                    <checkbox-field :id="`toggleAllRows`"
                                                    :name="`toggleAllRows`"
                                                    v-model="toggleAllRows"
                                                    color="secondary"
                                    ></checkbox-field>
                                </th>
                                <th>{{$ml.get('PHOTO')}}</th>
                                <th>{{$ml.get('FIRST_NAME')}}</th>
                                <th>{{$ml.get('LAST_NAME')}}</th>
                                <th>{{$ml.get('POSITION')}}</th>
                                <th>{{$ml.get('MOBILE_PHONE')}}</th>
                                <th>{{$ml.get('EMAIL')}}</th>
                                <th>{{$ml.get('OFFICE')}}</th>
                                <th>{{$ml.get('CREATED_AT')}}</th>
                                <th class="actions">{{$ml.get('ACTIONS')}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in tableRows" v-bind:key="index">
                                <td>
                                    <checkbox-field :id="`selectedRow${item.id}`"
                                                    :name="`selectedRow${item.id}`"
                                                    :value="selectedRows[item.id]"
                                                    v-model="selectedRows[item.id]"
                                                    color="secondary"
                                    ></checkbox-field>
                                </td>
                                <td class="td-avatar">
                                    <div class="position-relative">
                                        <span class="status-circle"
                                              :class="sentStatusClass(item)"
                                              v-tooltip="sentOnTooltip(item)"
                                        >
                                        </span>
                                        <span class="profile-avatar text-center">
                                            <img :src="item.attributes.profilePicture._url"
                                                 :alt="item.attributes.firstName + ' ' + item.attributes.lastName"
                                                 v-if="item.attributes.profilePicture">
                                            <font-awesome-icon v-else color="gray" icon="user" />
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <div class="ellipsis">{{item.attributes.firstName}}</div>
                                </td>
                                <td>
                                    <div class="ellipsis">{{item.attributes.lastName}}</div>
                                </td>
                                <td class="td-elipsis">
                                    {{item.attributes.position}}
                                </td>
                                <td>
                                    <div class="ellipsis">{{item.attributes.mobilePhone}}</div>
                                </td>
                                <td class="td-elipsis">
                                    {{item.attributes.email}}
                                </td>
                                <td>
                                    <div class="ellipsis" v-if="item.attributes.officeAddress">{{item.attributes.officeAddress.attributes.officeLabel}}</div>
                                </td>
                                <td>
                                    <div class="ellipsis">{{niceDate(item.attributes.createdAt)}}</div>
                                </td>
                                <td>
                                    <div class="table-action-btn-gorup">
                                        <button-field :disabled="sharing"
                                                      circle
                                                      outline
                                                      @click="previewCards([item.id], [item.attributes.cardToken])"
                                                      icon="eye"
                                                      class="table-action-btn"
                                                      v-tooltip="'Preview card'"
                                                      color="dark"></button-field>
                                        <button-field :disabled="sharing"
                                                      circle
                                                      outline
                                                      @click="shareCards([item.id])"
                                                      icon="share-square"
                                                      class="table-action-btn"
                                                      v-tooltip="'Send card'"
                                                      color="secondary"></button-field>
                                        <button-field @click="editEmployee(item.id)"
                                                      circle
                                                      outline
                                                      icon="edit"
                                                      class="table-action-btn"
                                                      v-tooltip="'Edit information'"
                                                      color="primary"></button-field>
                                        <button-field @click="deleteEmployee(item.id)"
                                                      circle
                                                      outline
                                                      icon="trash-alt"
                                                      class="table-action-btn"
                                                      v-tooltip="'Delete card'"
                                                      color="error"></button-field>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="table-bottom">
                <div class="table-bottom-buttons">
                    <button-field icon="share-square"
                                  color="secondary"
                                  :disabled="!selectedRowsTrue.length || sharing"
                                  :loading="sharing"
                                  @click="shareCards(selectedRowsTrue)">{{$ml.get('SEND_SELECTED')}}
                        ({{selectedRowsTrue.length}})
                    </button-field>
                    <button-field @click="bulkDelete"
                                  icon="trash-alt"
                                  color="error"
                                  :disabled="!selectedRowsTrue.length">{{$ml.get('DELETE_SELECTED')}}
                        ({{selectedRowsTrue.length}})
                    </button-field>
                </div>
                <div class="table-pagination">
                    <a href="#" @click="changeCurPage('prev')" :class="{ 'disable-click' : curPageValue==1 }">« {{$ml.get('Prev')}}</a>
                    <select-field :name="`curPage`"
                                  :id="`curPage`"
                                  :options="curPageOptions"
                                  :value="curPageValue"
                                  v-model="curPageValue"
                                  class="per-page-select">{{$ml.get('RESULTS_PAGE')}}:
                    </select-field>
                    <a href="#" @click="changeCurPage('next')" :class="{ 'disable-click' : curPageValue==maxPages }">{{$ml.get('Next')}} »</a>
                </div>

                <div class="table-result-per-page">
                    <select-field :name="`selectField`"
                                  :id="`selectField`"
                                  :options="perPageOptions"
                                  :value="perPageValue"
                                  v-model="perPageValue"
                                  class="per-page-select">{{$ml.get('RESULTS_PER_PAGE')}}
                    </select-field>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: "DashboardEmployeeCards",
        computed: {
            tableRows() {
                return this.$store.state.employeeCards;
            },
            resultsPerPage() {
                return this.$store.state.employeeCardsPP;
            },
            totalResults() {
                return this.$store.state.employeeCardCount;
            },
            resultsFrom() {
                if(this.$store.state.employeeCardCount == 0) {
                    return 0;
                }
                else {
                    return ((this.curPageValue-1)*this.perPageValue)+1;
                }

            },
            resultsTo() {
                let pageMaxResults = this.curPageValue*this.perPageValue;

                if(pageMaxResults > this.$store.state.employeeCardCount){
                    return this.$store.state.employeeCardCount;
                }else{
                    return pageMaxResults;
                }

            },
            getCurPageValue() {
                return this.$store.state.employeeCardPage;
            },
            curPageOptions() {
                let maxPageCount = Math.ceil(this.totalResults/this.resultsPerPage);
                let options = [
                        {
                        value: 1,
                        text: 1
                    }];
                let i = 2;
                while (i <= maxPageCount) {
                    options.push({
                        value: i,
                        text: i
                    });
                    i++;
                }
                return options;
            },
            maxPages() {
              return Math.ceil(this.totalResults/this.resultsPerPage);
            },
            selectedRowsTrue() {
                let selected = [];
                for (const key in this.selectedRows) {
                    if (this.selectedRows[key]) {
                        selected.push(key);
                    }
                }
                return selected;
            }
        },
        data() {
            return {
                toggleAllRows: false,
                perPageOptions: [
                    {
                        value: 5,
                        text: 5
                    },
                    {
                        value: 10,
                        text: 10
                    },
                    {
                        value: 25,
                        text: 25
                    },
                    {
                        value: 50,
                        text: 50
                    },
                    {
                        value: 100,
                        text: 100
                    },
                ],
                curPageValue: 1,
                perPageValue: 25,
                selectedRows: {},
                sharing: false,
                searchString: null,
            }
        },
        mounted() {
            let self = this;
            this.$store.dispatch('getCompany').then(() => {
                self.$store.dispatch('getEmployeeCardCount').then(() => {
                    self.$store.dispatch('getEmployeeCards').then(() => {
                    });
                    self.$store.dispatch('getCompAddresses').then(() => {
                    });
                });
            });
            this.$store.commit('closeRightModal');
        },
        methods: {
            sentStatusClass(item){
                if(item.attributes && item.attributes.sentAt){
                    return 'active';
                }else{
                    return 'not-sent';
                }
            },
            sentOnTooltip(item){
                if(item.attributes && item.attributes.sentAt){
                    return this.$ml.get('Sent_on') +' '+ this.niceDate(item.attributes.sentAt);
                }else{
                    return this.$ml.get('Card_not_yet_sent');
                }
            },
            niceDate(date){
                return moment(date).format('DD/MM/Y');
            },
            changeCurPage(direction) {

                if(direction == 'next') {
                    if(this.curPageValue < this.maxPages){
                        this.curPageValue++;
                    }
                }else if (direction == 'prev') {
                    if(this.curPageValue > 1){
                        this.curPageValue--;
                    }
                }

            },
            updateResultsPP(){
                let self = this;
                self.$store.dispatch('getEmployeeCardCount').then(() => {
                    self.$store.dispatch('getEmployeeCards').then(() => {
                    });
                    self.$store.dispatch('getCompAddresses').then(() => {
                    });
                });
            },

            toggleModal(componentName) {
                this.$store.commit('toggleDashboardModal', {name: componentName})
            },
            editEmployee(id = null) {
                this.$store.commit('toggleDashboardModal', {name: 'DashboardEmployeeCardsForm', data: {id: id}})
            },
            deleteEmployee(id) {
                // DashboardEmployeeCardsDelete
                this.$store.commit('toggleDashboardModal', {name: 'DashboardEmployeeCardsDelete', data: {ids: [id]}})
            },

            bulkDelete() {
                this.$store.commit('toggleDashboardModal', {
                    name: 'DashboardEmployeeCardsDelete',
                    data: {ids: this.selectedRowsTrue}
                })
            },
            shareCards(ids) {
                this.$store.commit('toggleDashboardModal', {name: 'EmployeeCards_Share', data: {ids: ids}})
            },
            previewCards(ids,token) {
                window.open(process.env.VUE_APP_BASE_API_URL+"cards/"+ids+"/"+token, '_blank');
            },
            doSearch(searchString){
                this.$store.dispatch('getEmployeeCards', {searchTerm : searchString})
            }
        },
        watch : {
            toggleAllRows(newVal){
                if(newVal){
                    this.selectedRows = {};
                    for (const key in this.tableRows) {
                        this.selectedRows[this.tableRows[key].id] = true;
                    }
                }else {
                    this.selectedRows = {};
                }
            },
            searchString(newSearch){
                clearTimeout(this.timeout);
                let self = this;
                self.timeout = setTimeout(() => {
                    self.doSearch(newSearch);
                }, 300);
            },
            perPageValue(newVal){
                this.$store.commit('setEmployeeCardsPP', newVal);
                this.$store.dispatch('setEmployeeCardPage', 1);
                this.curPageValue = 1;
                this.updateResultsPP();
            },
            curPageValue(newPageVal){
                this.$store.commit('setEmployeeCardPage', newPageVal);
                this.updateResultsPP();
            },
        }
    }
</script>

<style scoped>

</style>
