<template>
    <div class="eco-content-space h100 overflow-y-auto">
        <div class="white-box">
            <div class="table-top">
                <div class="table-top-buttons">
                    <button-field @click="addNewClient()"
                                  icon="plus"
                                  color="primary">{{$ml.get('ADD_CLIENT')}}
                    </button-field>
                </div>
                <div class="search-container">
                    <font-awesome-icon icon="search"/>
                    <input type="text" maxlength="20" name="searchInput"
                           v-model="searchString"
                           class="search-input form-field"
                           :placeholder="$ml.get('search_by_name_vat_website')"
                    />
                </div>
                <!--<div class="table-top-search">-->
                <!--{{$ml.get('Search')}}-->
                <!--</div>-->
            </div>
            <div class="eco-content-scroll">
                <div  class="table-list-wrapper">
                    <table class="table-list">
                        <thead class="font-bebas-neue">
                            <tr>
                                <th></th>
                                <th></th>
                                <th>{{$ml.get('COMPANY_NAME')}}</th>
                                <th>{{$ml.get('STATUS')}}</th>
                                <th>{{$ml.get('PROMO')}}</th>
                                <th>{{$ml.get('CREATED_ON')}}</th>
                                <th >{{$ml.get('TRIAL_UNTIL')}}</th>
                                <th>{{$ml.get('RESPONSIBLE')}}</th>
                                <th>{{$ml.get('BILLING_EMAIL')}}</th>
                                <th>{{$ml.get('ACTIVE_CARDS')}}</th>
                                <th class="actions">{{$ml.get('ACTIONS')}}</th>
                            </tr>
                        </thead>

                        <transition-group name="fade" tag="tbody">
                            <tr v-for="(item, index) in tableRows" v-bind:key="'client-'+index" :class="suspendedRow(item)">
                                <td>
                                    <checkbox-field :id="`selectedRow${item.id}`"
                                                    :nqme="`selectedRow${item.id}`"
                                                    v-model="selectedRows[item.id]"
                                                    color="secondary"
                                    ></checkbox-field>
                                </td>


                                <td v-if="item.attributes.isAgency" class="hasCursor comp-preview" @click="goToClientCards(item)">
                                    <a href="#">
                                        <div class="ellipsis">
                                            <img v-if="item.attributes.logo"
                                                 :src="item.attributes.logo._url" class="company-logo"/>
                                        </div>
                                    </a>
                                </td>
                                <td v-else class="hasCursor" @click="goToClientCards(item)">
                                    <div class="ellipsis">
                                        <img v-if="item.attributes.logo" :src="item.attributes.logo._url" class="company-logo"/>
                                    </div>
                                </td>
                                <td v-if="item.attributes.isAgency == true && item.id != userCompany.id"
                                    class="td-elipsis-150 hasCursor" @click="showAgencyClients(item)">
                                    <a href="#">
                                        {{item.attributes.name}}
                                    </a>
                                </td>
                                <td v-else
                                    class="td-elipsis-150 hasCursor" @click="goToClientCards(item)">
                                    {{item.attributes.name}}
                                </td>
                                <td class="td-elipsis">
                                    <span :class="'text-'+computeStatus(item).color">
                                        <font-awesome-icon :icon="computeStatus(item).icon"/>
                                        {{computeStatus(item).text}}
                                    </span>
                                </td>
                                <td class="td-elipsis">
                                    {{extractPromo(item)}}
                                </td>
                                <td class="td-elipsis">
                                    {{item.attributes.createdAt | dateFormat}}
                                </td>
                                <td v-if="isAdmin" class="td-elipsis" :class="trialExpired(item) ? 'text-danger' : 'text-success'">
                                    {{item.attributes.trialUntil | dateFormat}}
                                </td>

                                <td class="td-elipsis">
                                    {{item.attributes.responsible}}
                                </td>
                                <td>
                                    <div class="ellipsis">{{item.attributes.billingEmail}}</div>
                                </td>
                                <td>
                                    <div class="ellipsis">{{calculateCards(item)}}</div>
                                </td>
                                <td>
                                    <div class="table-action-btn-gorup">
                                        <button-field
                                                      v-if="isAdmin"
                                                      circle
                                                      outline
                                                      @click="editAgencySettings(item.id)"
                                                      v-tooltip="$ml.get('tooltip_admin_settings')"
                                                      icon="drafting-compass"
                                                      class="table-action-btn"
                                                      color="dark"></button-field>
                                        <button-field :disabled="sharing"
                                                      circle
                                                      outline
                                                      @click="copySelfOnboardLink(item)"
                                                      v-tooltip="$ml.get('tooltip_onboard_link')"
                                                      icon="link"
                                                      class="table-action-btn"
                                                      color="secondary"></button-field>
                                        <button-field @click="editClient(item.id)"
                                                      circle
                                                      outline
                                                      v-tooltip="`Edit client`"
                                                      icon="edit"
                                                      class="table-action-btn"
                                                      color="primary"></button-field>
                                        <button-field @click="suspendClient(item.id)"
                                                      circle
                                                      outline
                                                      :activated="isSuspended(item)"
                                                      :disabled="item.attributes.isAgency"
                                                      v-tooltip="suspendMesssage(item)"
                                                      icon="moon"
                                                      color="dark"
                                                      class="table-action-btn"></button-field>
                                        <button-field @click="deleteClient(item.id)"
                                                      circle
                                                      outline
                                                      v-tooltip="`Delete client`"
                                                      icon="trash-alt"
                                                      class="table-action-btn"
                                                      :disabled="forbidDelete(item)"
                                                      color="error"></button-field>
                                    </div>
                                </td>
                            </tr>
                        </transition-group>

                    </table>
                </div>

            </div>
            <div class="table-bottom">
                <div class="table-bottom-buttons">
                    <button-field @click="bulkDelete"
                                  icon="trash-alt"
                                  color="error"
                                  :disabled="!selectedRowsTrue.length">{{$ml.get('DELETE_SELECTED')}}
                        ({{selectedRowsTrue.length}})
                    </button-field>
                    <div class="d-inline-block ml-2">
                        <checkbox-field id="showSuspended"
                                        name="showSuspended"
                                        color="secondary"
                                        v-model="showSuspended"
                        >{{$ml.get('show_suspended')}}</checkbox-field>
                    </div>
                </div>

                <div class="table-result-per-page">
                    <select-field :name="`selectField`"
                                  :id="`selectField`"
                                  :options="perPageOptions"
                                  :value="perPageValue"
                                  v-model="perPageValue"
                                  class="per-page-select">{{$ml.get('RESULTS_PER_PAGE')}}
                    </select-field>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import CheckboxField from '@/components/global/CheckboxField';

    export default {
        name: "DashboardClients",
        components: {
            CheckboxField
        },
        computed: {
            userCompany() {
                return this.$store.state.userCompany;
            },
            isAdmin() {
                return this.$store.state.user.attributes.isAdmin;
            },
            tableRows() {
                let rows = this.$store.state.privateAgencyClients;
                if( this.$store.state.userCompany
                    && this.$store.state.userCompany.attributes.isAgency
                    && !this.$store.state.userCompany.attributes.core
                ){
                    const isUserCompanyPresent = rows.some(item => item.id === this.$store.state.userCompany.id);
                    if (!isUserCompanyPresent) {
                        rows.push(this.$store.state.userCompany);
                    }
                }

                let suspended = rows.filter((item) => {
                    return item.attributes.suspended;
                });

                let active = rows.filter((item) => {
                    return !item.attributes.suspended;
                });

                return this.showSuspended ? suspended : active;
            },
            selectedRowsTrue() {
                let selected = [];
                for (const key in this.selectedRows) {
                    if (this.selectedRows[key]) {
                        selected.push(key);
                    }
                }
                return selected;
            }
        },
        data() {
            return {
                selectedAgency: null,
                selectedClientOfId: null,
                perPageOptions: [
                    {
                        value: 10,
                        text: 10
                    },
                    {
                        value: 25,
                        text: 25
                    },
                    {
                        value: 50,
                        text: 50
                    },
                    {
                        value: 100,
                        text: 100
                    },
                ],
                perPageValue: 25,
                selectedRows: {},
                sharing: false,
                wizzardOpened: true,
                searchString: null,
                timeout: null,
                showSuspended: false,
                checkingAgencies: false,
            }
        },
        created() {
            this.$store.dispatch('getCompany').then((userCompany) => {
                let agencyId = userCompany.id;

                const determineAgencyId = () => {
                    if (userCompany.attributes.core) {
                        if (this.$route.params.agencyId) {
                            // Wrap the return value in a Promise
                            return Promise.resolve(this.$route.params.agencyId);
                        } else {

                            return Promise.resolve(this.$store.state.agencyId);

                        }
                    } else {
                        // Wrap the return value in a Promise
                        return Promise.resolve(agencyId);
                    }
                };

                determineAgencyId().then((finalAgencyId) => {
                    this.$store.commit('setAgencyId', finalAgencyId);
                    this.$store.dispatch('getClientsOfAgency', { agencyId: finalAgencyId });
                    //this.$store.dispatch('getAgencyClients', { clientOf: finalAgencyId });
                });
            });

            this.$store.commit('closeRightModal');
        },


        methods: {
            showClients(item){
                this.selectedAgency = item;
                this.$store.dispatch('getAgencyClients', {clientOf:item})
            },
            calculateCards(item) {
                return item.attributes.currentCardCount ? item.attributes.currentCardCount : 0;
            },
            calculateAgencyCards(item){
                let total = 0;
                if(item.attributes.relatedCompanies){
                    item.attributes.relatedCompanies.forEach((client) => {
                        if(client.attributes.currentCardCount){
                            total += client.attributes.currentCardCount;
                        }
                    })
                }
                return total;
            },
            clearSelectedAgency(){
                this.selectedAgency = null;
                this.checkingAgencies = true;
                this.$store.dispatch('getAllAgencies')
            },
            computeStatus(item){
                let output = {
                    text: '',
                    color: '',
                    icon: 'check'
                };

                let company = item;
                let cardCount = company.attributes.currentCardCount;
                let users = company.attributes.relatedUsers ? company.attributes.relatedUsers : [];
                let cardConfig = company.attributes.relatedCardConfig ? company.attributes.relatedCardConfig : null;

                let hasDesign = (cardConfig && cardConfig.attributes.designVer > 1);
                let hasUsers = (users.length > 0);
                let hasCards = (cardCount > 0);

                if(!hasCards && !hasUsers &&  !hasDesign){
                    output.text = 'New';
                    output.color = 'blue';
                    output.icon = 'star';
                }else if(!hasDesign){
                    output.text = 'Missing design';
                    output.color = 'primary';
                    output.icon = 'palette';

                } else if (!hasUsers) {
                    output.text = 'No user assigned';
                    output.color = 'primary';
                    output.icon = 'user';
                } else if (!hasCards) {
                    output.text = 'Ready to onboard';
                    output.color = 'dark';
                    output.icon = 'calendar';
                } else {
                    output.text = 'Active';
                    output.color = 'secondary';
                    output.icon = 'check';
                }

                return output;
            },

            showAgencyClients(item){
                this.selectedAgency = item;
                this.checkingAgencies = false;
                this.$store.dispatch('getClientsOfAgency', {clientOf:item})
            },

            trialExpired(item){
                let trialUntil = new Date(item.attributes.trialUntil);
                let today = new Date();
                return trialUntil < today;
            },

            extractPromo(item) {
                if (item.attributes.usedPromo
                    && item.attributes.usedPromo.attributes
                    && item.attributes.usedPromo.attributes.code
                ) {
                    return item.attributes.usedPromo.attributes.code;
                } else {
                    return '';
                }
            },

            doSearch(searchString){
                this.$store.dispatch('getClientsOfAgency', {searchTerm : searchString, clientOf: this.selectedClientOfId});
            },
            suspendedRow(item){
                if(this.isSuspended(item)){
                    return 'suspended';
                }else{
                    return '';
                }
            },
            copySelfOnboardLink(item){

                let invitationLink = window.location.protocol + '//' + window.location.host
                    +'/addme/'+item.id+'/'+item.attributes.frameSecret;

                navigator.clipboard.writeText(invitationLink);

                this.$notify({
                    group: 'notification',
                    type: 'success',
                    text: this.$ml.get('notification_onboard_link_copied')
                });

            },
            suspendMesssage(item){
                if(this.isSuspended(item)){
                    return this.$ml.get('RESTORE_CLIENT');
                }else {
                    return this.$ml.get('SUSPEND_CLIENT');
                }
            },
            isSuspended(item){
                return item.attributes.suspended;
            },
            suspendClient(id){
                this.$store.commit('toggleDashboardModal', {name: 'DashboardClientSuspend', data: {ids: [id]}})
            },
            forbidDelete(item){
                return item.attributes.isAgency;
            },
            forbidAgencyDelete(item){
                return item.attributes.relatedCompanies.length > 0;
            },
            toggleModal(componentName) {
                this.$store.commit('toggleDashboardModal', {name: componentName})
            },
            addNewClient(){
                this.$router.push({name: 'client-add', params: {agencyId: this.$store.state.agencyId}});
            },
            editAgencySettings(id = null) {
                this.$store.commit('toggleDashboardModal', {name: 'DashboardAgencySettingsForm', data: {id: id, dataSet: 'agencyClients'}})
            },
            editClient(id = null) {
                this.$store.commit('toggleDashboardModal', {name: 'DashboardClientsForm', data: {id: id, dataSet: 'agencyClients'}})
                this.wizzardOpened = true;
            },
            deleteClient(id, isAgency = false) {
                // DashboardEmployeeCardsDelete
                this.$store.commit('toggleDashboardModal', {name: 'DashboardClientsDelete', data: {ids: [id], isAgency: isAgency, dataSet: 'agencyClients'}})
            },
            bulkDelete() {
                this.$store.commit('toggleDashboardModal', {
                    name: 'DashboardClientsDelete',
                    data: {ids: this.selectedRowsTrue}
                })
            },
            goToClientCards(clientCompany) {
                let self = this;
                this.$store.dispatch('changeImpersonation', clientCompany).then(function(response){
                    if(response.attributes.impersonating){
                        self.$notify({
                            group: 'notification',
                            type: 'success',
                            text: "Switching to managing "+clientCompany.attributes.name+"...",
                        });
                        self.$store.commit('setCardCompany',clientCompany);
                        setTimeout(function(){
                            self.$router.push({name: 'home'});
                        }, 1500);
                    }

                });

                // if (!this.sharing) {
                //     this.sharing = true;
                //     this.$store.dispatch('shareCards', ids).then(() => {
                //         this.$notify({
                //             group: 'notification',
                //             type: 'success',
                //             text: this.$ml.get('notification_share_card'),
                //         });
                //         this.sharing = false;
                //     }).catch((e) => {
                //         this.$notify({
                //             group: 'notification',
                //             type: 'error',
                //             title: this.$ml.get('notification_error_title'),
                //             text: this.$ml.get('notification_error_general'),
                //         });
                //         this.sharing = false;
                //         self.$checkTokenExp(e);
                //     })
                // }
            },
        },
        watch : {
            searchString(newSearch) {
                clearTimeout(this.timeout);
                let self = this;
                self.timeout = setTimeout(() => {
                    self.doSearch(newSearch);
                }, 300);
            },
        }
    }
</script>

<style scoped>
    .per-page-select {
        width: 110px;
    }
    .hasCursor {
        cursor: pointer;
    }
    .suspended {
        opacity: 0.5;
    }

    .text-success {
        color: var(--color-secondary);
    }

    .text-danger {
        color: var(--color-error);
    }

    .selected-agency-preview {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
    }

    .company-logo {
        max-height: 16px;
        max-width: 40px;
    }

</style>
