<template>
    <div class="w-100">
        <table spacing="0" class="eco-pricing-table w-100">
            <thead>
            <tr>
                <th class="text-left font-bebas-neue medium" colspan="2">Pricing tier</th>
                <th class="text-left font-bebas-neue">Price per card</th>
            </tr>
            </thead>

            <tbody>
                <tr v-for="(oneLevel, index) in tiers" :key="'lvl-'+index" :class="oneLevel.active ? 'eco-current-tier' : ''">
                    <td class="mini-cell">T{{index+1}}</td>
                    <td class="text-right pt-2" v-if="oneLevel.to < 10000">From {{ oneLevel.from | thousands }} to {{ oneLevel.to  | thousands }} cards</td>
                    <td class="text-right pt-2" v-else>More than {{ oneLevel.from  | thousands }} cards</td>

                    <td class="text-left pt-2">{{ {currency: 'EUR', amount: oneLevel.price} | currency }}/card</td>
                </tr>
            </tbody>

        </table>
    </div>

</template>
<script>

export default {
    data() {
        return {
            tiers: [],
            currentCardCount: 0,
            activeLevel: null,
        }
    },
    props: {
        hardCoded: {
            type: Boolean,
            default: false
        }
    },
    created() {

        this.$store.dispatch('getCompany').then((company)=>{
            if(!company || this.hardCoded){
                this.currentCardCount = 1000;
                this.tiers = [
                    {
                        tier: 1,
                        from: 1,
                        to: 99,
                        price: 2.00,
                        active: true
                    },
                    {
                        tier: 2,
                        from: 100,
                        to: 499,
                        price: 1.90,
                        active: false
                    },
                    {
                        tier: 3,
                        from: 500,
                        to: 1999,
                        price: 1.80,
                        active: false
                    },
                    {
                        tier: 4,
                        from: 2000,
                        to: 9999,
                        price: 1.70,
                        active: false
                    },
                    {
                        tier: 5,
                        from: 10000,
                        to: 999999999,
                        price: 1.50,
                        active: false
                    },
                ]
            }
            else {
                this.currentCardCount = company.attributes.currentCardCount;
                this.currentCardCount = company.attributes.cardCount;

                this.$store.dispatch('getMyPricingTiers').then((pricing)=>{

                    let scopeMaxLevel = 5;
                    let min = 1;
                    let self = this;

                    if(pricing.id){
                        for (let i = 1; i <= scopeMaxLevel; i++) {
                            if(pricing.attributes[`pricingTier${i}`]){
                                let level = {
                                    tier: i,
                                    from: min,
                                    to: pricing.attributes[`threshold${i}`]-1,
                                    price: pricing.attributes[`pricingTier${i}`],
                                    active: self.currentCardCount >= min && self.currentCardCount <= pricing.attributes[`threshold${i}`]-1
                                }
                                self.tiers.push(level);
                                if(level.active){
                                    self.activeLevel = level;
                                }
                            }
                            min = pricing.attributes[`threshold${i}`];
                        }
                    }


                });
            }

        });


    },
    watch: {
        activeLevel(newVal){
            this.$emit('level', newVal);
            this.$emit('count', this.currentCardCount);
        }
    }

}

</script>

<style scoped>
.eco-pricing-table {
    border: 0px solid;
    border-spacing: 0;
    font-size: 0.8em;
    max-width: 420px;
    margin: 0 auto;
}
.eco-pricing-table th {
    font-size: 1.2em;
}


tr > * {
    padding: 4px 10px;
    width:45%;
}
tr > td.mini-cell {
    width: 10%;
}


.eco-pricing-table thead th {
    border-bottom: 2px solid #999;
}


.eco-pricing-table thead th+th,
.eco-pricing-table td+td {
    border-left: 1px solid #999;
}

.eco-current-tier {
    background: var(--color-primary);
    color: white;
}

</style>
