import Vue from 'vue'
import VueRouter from 'vue-router'

import Authenticate from '../components/authenticate/Authenticate'
import AuthenticateLogin from '../components/authenticate/AuthenticateLogin'
import AuthenticateOnboard from '../components/authenticate/AuthenticateOnboard'
import AuthenticateForgotten from '../components/authenticate/AuthenticateForgotten'
import AuthenticateResetPassword from '../components/authenticate/AuthenticateResetPassword'

import StyleComponents from '../components/global/StyleComponents'

import NotAuthorized from "@/components/global/NotAuthorized.vue";

import DashboardLayout from '../components/layout/Dashboard'
import DashboardEmployeeCards from '../components/pages/EmployeeCards'
import DashboardCardDesign from '../components/pages/CardDesign'
import DashboardCompanyCoordinates from '../components/pages/ClientCoordinates'
import DashboardBilling from '../components/pages/Billing'
import DashboardClients from '../components/admin/ClientList'
import DashboardAgencies from '../components/admin/AgencyList'
import DashboardAdmin from '../components/admin/DashboardAdmin.vue'
import UserList from '../components/admin/UserList'

import addClient from "@/components/pages/ClientAdd";
import ClientInformation from "@/components/pages/ClientInformation";
import DashboardProfile from '../components/pages/UserProfile'

import AdminEmployeeCards from '../components/pages/AdminEmployeeCards'
import AdminEstimates from '../components/pages/AdminEstimates'

import store from '../store/index';
import EmployeeOnboard from "../components/onboard/EmployeeOnboard";
import ProfileSuspended from "../components/global/ProfileSuspended"
import TermsRegular from "@/components/global/TermsRegular.vue";
import TermsAgency from "@/components/global/TermsAgency.vue";
import trialWizard from "@/components/wizzard/addClientWizard.vue";
import RequestTrial from '@/components/authenticate/RequestTrial'

import Invoices from "@/components/pages/Invoices.vue";
import ClientUserList from "@/components/admin/ClientUserList.vue";

//import moment from "moment";

class Router extends VueRouter {
    resolve(to, current, append) {
        const route = { ...to };
        route.params = { ...route.params, language: 'en' };
        return super.resolve(route, current, append);
    }
}


Vue.use(Router);

const routes = [
    {
        path: '/auth',
        component: Authenticate,
        meta: {
            access: 'public',
        },
        beforeEnter: (to, from, next) => {
            store.dispatch('currentUser').then(() => {
                if (!store.state.isAuthenticated) {
                    next();
                } else if(store.state.user.attributes.isAdmin){
                    next({name: 'adminCards'});
                } else {
                    next('/');
                }
            }).catch(() => {
                if (!store.state.isAuthenticated) {
                    next();
                } else if(store.state.user.attributes.isAdmin){
                    next({name: 'adminCards'});
                } else {
                    next('/')
                }
            });
        },
        children: [
            {
                path: '',
                name: 'auth',
                component: AuthenticateLogin,
                meta: {
                    access: 'public',
                },
            },
            {
                path: 'forgotten-password',
                name: 'forgottenPassword',
                component: AuthenticateForgotten,
                meta: {
                    access: 'public',
                },
            },
            {
                path: 'password-reset',
                name: 'passwordReset',
                component: AuthenticateResetPassword,
                meta: {
                    access: 'public',
                },
            },
            {
                path: 'onboard/:userId/:secret',
                name: 'onboard',
                component: AuthenticateOnboard,
                meta: {
                    access: 'public',
                },
            },
        ]
    },
    {
        path: '/style-components',
        name: 'StyleComponents',
        component: StyleComponents,
        meta: {
            access: 'admin',
        },
    },
    {
        path: '/suspended',
        name: 'suspended',
        component: ProfileSuspended,
        meta: {
            access: 'client',
        },
    },
    {
        path: '/not-authorised/',
        name: 'not-authorised',
        component: NotAuthorized,
        meta: {
            access: 'public',
        },
    },
    {
        path: '/terms-regular',
        name: 'terms-regular',
        component: TermsRegular,
        meta: {
            access: 'client',
        },
    },
    {
        path: '/terms-agency',
        name: 'terms-agency',
        component: TermsAgency,
        meta: {
            access: 'client',
        },
    },

    {
        path: '/addme/:compId/:frameSecret',
        name: 'addme',
        component: EmployeeOnboard,
        meta: {
            access: 'public',
        },
    },
    {
        path: '/trial-signup/:agencyId?',
        name: 'trial-signup',
        component: trialWizard,
        meta: {
            access: 'public',
        },
    },
    {
        path: '/signup/:agencyId?',
        name: 'signup',
        component: trialWizard,
        meta: {
            access: 'public',
        },
    },
    {
        path: '/request-trial/',
        name: 'requestTrial',
        component: RequestTrial,
        meta: {
            access: 'public',
        },
    },
    {
        path: '/',
        // beforeEnter: (to, from, next) => {
        //
        // },
        component: DashboardLayout,
        meta: {
            access: 'client',
        },
        children: [
            {
                path: '',
                name: 'home',
                component: DashboardEmployeeCards,
                meta: {
                    access: 'client',
                },
            },
            {
                path: 'card-reports',
                name: 'adminCards',
                component: AdminEmployeeCards,
                meta: {
                    access: 'client',
                },
            },
            {
                path: 'client-users',
                name: 'clientUsers',
                component: ClientUserList,
                meta: {
                    access: 'agency',
                },
            },
            {
                path: 'billing-estimates',
                name: 'billingEstimate',
                component: AdminEstimates,
                meta: {
                    access: 'agency',
                },
            },
            {
                path: 'card-design',
                name: 'cardDesign',
                component: DashboardCardDesign,
                meta: {
                    access: 'client',
                },
                beforeEnter: (to, from, next) => {
                    store.dispatch('getCompany').then(() => {
                        store.dispatch('getCardConfig').then((resp) => {
                            store.commit('setActiveTemplate', resp.attributes.template);
                            if (store.state.cardConfig) {
                                next();
                            } else {
                                next({name: 'adminCards'});
                            }
                        })
                    })
                }
            },

            {
                path: 'client/add/:agencyId?',
                name: 'client-add',
                component: addClient,
                meta: {
                    access: 'agency',
                },
            },
            {
                path: 'client/information',
                name: 'client-information',
                component: ClientInformation,
                meta: {
                    access: 'client',
                },
            },
            {
                path: 'client/addresses',
                name: 'office-addresses',
                component: DashboardCompanyCoordinates,
                meta: {
                    access: 'client',
                },
            },
            {
                path: 'billing',
                name: 'billing',
                component: DashboardBilling,
                meta: {
                    access: 'client',
                },
            },
            {
                path: 'profile',
                name: 'profile',
                component: DashboardProfile,
                meta: {
                    access: 'client',
                },
            },
            {
                path: '/admin/users',
                name: 'users',
                component: UserList,
                meta: {
                    access: 'agency',
                },
            },
            {
                path: '/admin/clients/:agencyId?',
                name: 'clients',
                component: DashboardClients,
                meta: {
                    access: 'agency',
                },
            },
            {
                path: '/admin/agencies',
                name: 'agencies',
                component: DashboardAgencies,
                meta: {
                    access: 'admin',
                },
            },
            {
                path: '/admin/dashboard',
                name: 'admin-dashboard',
                component: DashboardAdmin,
                meta: {
                    access: 'admin',
                },
            },

            {
                path: '/invoices',
                name: 'invoices',
                component: Invoices,
                meta: {
                    access: 'agency',
                },
            },
        ]
    },

];

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

function proceedWithNavigation(to, next) {
    //console.log("Proceeding with navigation to: " + to.name);
    //CHECK I: IS ROUTE PUBLIC?
    if(to.meta.access == 'public'){
        next();
        console.log("Check I: Route is public");
    }else {

        //CHECK II: IS THE USER LOGGED-IN?
        if(!store.state.isAuthenticated){
            //NOT AUTHENTICATED
            next('/auth');
            console.log("Check II: Not authenticated");
        } else {

            let isCore = store.getters['getIsCore'];
            let isAgency = store.state.userCompany.attributes.isAgency;

            //CHECK III: IS THIS A CORE USER?
            if(isCore){
                //All routes are allowed
                next();
                console.log("Check III: Is core user");

            } else {
                //CHECK IV: IS THE USER SUSPENDED?
                let isSuspended = store.getters['getIsSuspended'];
                let trialDays = store.getters['getRemainingTrial'];

                if(isSuspended || (trialDays < 0 && trialDays !== null)){

                    let allowedRoutes = ['profile', 'terms-regular', 'terms-agency', 'suspended'];
                    if(allowedRoutes.includes(to.name)){
                        next();
                        //console.log("Check IV: Suspended or trial expired but allowed route");
                        return;
                    } else {
                        next('/suspended');
                        //console.log("Check IV: Suspended or trial expired");
                    }

                } else {
                    //ACCOUNT IS STILL ACTIVE

                    //CHECK V: IS THE USER AGENCY OR CLIENT?
                    if(isAgency){
                        //AGENCY USER
                        if(to.meta.access == 'agency' || to.meta.access == 'client'){
                            next();
                            //console.log("Check V: Is agency user");
                        }else {
                            next('/not-authorised');
                            //console.log("Check V: Is agency user but route is admin");
                        }//END CHECK V
                    } else {
                        //CHECK VI: IS THE ROUTE PERMITTED FOR CLIENTS
                        //CLIENT USER
                        if(to.meta.access == 'client'){
                            next();
                            console.log("Check VI: ok to proceed to client route");
                        }else {
                            next('/not-authorised');
                            console.log("Check VI: Is regular user but route is admin or agenycy");
                        }
                    }//END CHECK VI


                } //END CHECKS IV, V, VI

            } //END CHECK III

        } //END CHECK II

    }//END CHECK I

}

router.beforeEach(async (to, from,next) => {
        //Make sure store is loaded before proceeding
        if (store.getters['isUserDataLoaded']) {
            proceedWithNavigation(to, next);
        } else {
            // Wait for the user data to load
            try {
                await store.dispatch('loadUserData');
                proceedWithNavigation(to, next);
            } catch (error) {
                console.error('Error loading user data', error);
                // Handle error, redirect to error page, or show notification
                next({name : 'auth' });
            }
        }
});

export default router
