<template>
    <div class="eco-content-space h100">
        <div class="employee-cards h100">
            <div class="table-top">
                <div class="table-top-buttons">
                    <button-field @click="addNewUser()"
                                  icon="plus"
                                  color="primary">{{$ml.get('ADD_USER')}}
                    </button-field>
                </div>
<!--                <div class="table-top-search">-->
<!--                    {{$ml.get('Search')}}-->
<!--                </div>-->
                <div class="search-container">
                    <font-awesome-icon icon="search"/>
                    <input type="text" maxlength="20" name="searchInput"
                           v-model="searchString"
                           class="search-input form-field"
                           :placeholder="$ml.get('search_by_name')"
                    />
                </div>

            </div>
            <div class="eco-content-scroll">
                <div class="table-list-wrapper">
                    <table class="table-list">
                        <thead class="font-bebas-neue">
                        <tr>
                            <th></th>
                            <th></th>
                            <th>{{$ml.get('NAMES')}}</th>
                            <th>{{$ml.get('CREATED_ON')}}</th>
                            <th>{{$ml.get('LOGIN_EMAIL')}}</th>
                            <th>{{$ml.get('COMPANY')}}</th>
                            <th>{{$ml.get('STATUS')}}</th>
                            <th>{{$ml.get('LAST_LOGGED_ON')}}</th>
                            <th>{{$ml.get('LOGIN_COUNT')}}</th>
                            <th class="actions">{{$ml.get('ACTIONS')}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in tableRows" v-bind:key="index" :class="suspendedRow(item)">
                            <td>
                                <checkbox-field :id="`selectedRow${item.id}`"
                                                :nqme="`selectedRow${item.id}`"
                                                v-model="selectedRows[item.id]"
                                                color="secondary"
                                ></checkbox-field>
                            </td>
                            <td class="td-avatar">
                                <div class="position-relative hasCursor" @click="editUser(item.id)">
                                    <span class="profile-avatar text-center">
                                        <img :src="item.attributes.avatar._url"
                                             :alt="item.attributes.firstName + ' ' + item.attributes.lastName"
                                             v-if="item.attributes.avatar">
                                        <font-awesome-icon v-else color="gray" icon="user" />
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div @click="editUser(item.id)"
                                     class="td-elipsis-100 hasCursor">{{item.attributes.firstName}} {{item.attributes.lastName}}</div>
                            </td>
                            <td>
                                <div class="ellipsis">{{niceDate(item.attributes.createdAt)}}</div>
                            </td>
                            <td class="td-elipsis">
                                {{item.attributes.username}}
                            </td>
                            <td>
                                <div v-if="item.attributes.company"
                                     class="hasCursor"
                                     @click="openEditCompany(item.attributes.company.id)"
                                >
                                    <img v-if="item.attributes.company.attributes.logo" :src="item.attributes.company.attributes.logo._url" class="company-logo"/>
                                    <div  class="ellipsis">{{item.attributes.company.attributes.name}}</div>
                                </div>

                            </td>
                            <td>
                                <div class="ellipsis" :class="item.attributes.onboarded ? 'onboarded' : 'pending'"><span class="status-text">{{niceStatus(item)}}</span><span v-if="hasTrial(item)" class="trial-subscript">{{$ml.get('trial_until')}}: {{niceDate(item.attributes.trialUntil)}}</span></div>
                            </td>
                            <td>
                                <div class="ellipsis">{{niceDate(item.attributes.createdAt)}}</div>
                            </td>
                            <td>
                                <div class="ellipsis" v-if="item.attributes.loginCount">{{item.attributes.loginCount}}</div>
                            </td>
                            <td>
                                <div class="table-action-btn-gorup">
                                    <button-field @click="copyLink(item)"
                                                  circle
                                                  outline
                                                  v-tooltip="`Copy inivation link`"
                                                  icon="link"
                                                  class="table-action-btn"
                                                  color="secondary"
                                                  :disabled="item.attributes.onboarded"
                                    ></button-field>

                                    <button-field @click="editUser(item.id)"
                                                  circle
                                                  outline
                                                  v-tooltip="`Edit user`"
                                                  icon="edit"
                                                  class="table-action-btn"
                                                  color="primary"></button-field>
                                    <button-field @click="suspendUser(item.id)"
                                                  circle
                                                  outline
                                                  :activated="isSuspended(item)"
                                                  v-tooltip="suspendMesssage(item)"
                                                  icon="moon"
                                                  color="dark"
                                                  class="table-action-btn"></button-field>
                                    <button-field @click="deleteUser(item.id)"
                                                  circle
                                                  outline
                                                  v-tooltip="$ml.get('tooltip_DELETE_USER')"
                                                  icon="trash-alt"
                                                  class="table-action-btn"
                                                  :disabled="forbidDelete(item)"
                                                  color="error"></button-field>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            </div>
            <div class="table-bottom">
                <div class="table-bottom-buttons">
                    <button-field @click="bulkDelete"
                                  icon="trash-alt"
                                  color="error"
                                  :disabled="!selectedRowsTrue.length">{{$ml.get('DELETE_SELECTED')}}
                        ({{selectedRowsTrue.length}})
                    </button-field>
                </div>

                <div class="table-pagination">
                    <a href="#" @click="changeCurPage('prev')" :class="{ 'disable-click' : curPageValue==1 }">« {{$ml.get('Prev')}}</a>
                    <select-field :name="`curPage`"
                                  :id="`curPage`"
                                  :options="curPageOptions"
                                  :value="curPageValue"
                                  v-model="curPageValue"
                                  class="per-page-select">{{$ml.get('RESULTS_PAGE')}}:
                    </select-field>
                    <a href="#" @click="changeCurPage('next')" :class="{ 'disable-click' : curPageValue==maxPages }">{{$ml.get('Next')}} »</a>
                </div>

                <div class="table-result-per-page">
                    <select-field :name="`selectField`"
                                  :id="`selectField`"
                                  :options="perPageOptions"
                                  :value="perPageValue"
                                  v-model="perPageValue"
                                  class="per-page-select">{{$ml.get('RESULTS_PER_PAGE')}}
                    </select-field>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    import Parse from "parse";
    export default {
        name: "DashboardClients",
        computed: {
            curUser() {
                return Parse.User.current();
            },

            tableRows() {
                return this.$store.state.visibleUsers;
            },
            totalResults() {
                return this.$store.state.usersTotalCount;
            },
            maxPages() {
                return Math.ceil(this.totalResults/this.resultsPerPage);
            },
            resultsPerPage() {
                return this.$store.state.usersPP;
            },
            curPageOptions() {
                let maxPageCount = Math.ceil(this.totalResults/this.resultsPerPage);
                let options = [
                    {
                        value: 1,
                        text: 1
                    }];
                let i = 2;
                while (i <= maxPageCount) {
                    options.push({
                        value: i,
                        text: i
                    });
                    i++;
                }
                return options;
            },
            selectedRowsTrue() {
                let selected = [];
                for (const key in this.selectedRows) {
                    if (this.selectedRows[key]) {
                        selected.push(key);
                    }
                }
                return selected;
            }
        },
        data() {
            return {
                perPageOptions: [
                    {
                        value: 10,
                        text: 10
                    },
                    {
                        value: 25,
                        text: 25
                    },
                    {
                        value: 50,
                        text: 50
                    },
                    {
                        value: 100,
                        text: 100
                    },
                ],
                curPageValue: 1,
                perPageValue: 25,
                selectedRows: {},
                sharing: false,
                wizzardOpened: true,
                searchString: null,
                timeout: null
            }
        },
        mounted() {
            this.fetchUsers();
        },
        methods: {
            fetchUsers(){
                this.$store.dispatch('getTotalUserCount').then(() => {
                    this.$store.dispatch('getAvailableUsers',{isAdmin: this.curUser.attributes.isAdmin});
                });
            },

            changeCurPage(direction) {

                if(direction == 'next') {
                    if(this.curPageValue < this.maxPages){
                        this.curPageValue++;
                    }
                }else if (direction == 'prev') {
                    if(this.curPageValue > 1){
                        this.curPageValue--;
                    }
                }

            },
            doSearch(searchString){
                this.$store.dispatch('getAvailableUsers', {searchTerm : searchString, isAdmin: this.curUser.attributes.isAdmin})
            },
            hasTrial(item){
                return !!item.attributes.trialUntil;
            },
            copyLink(item){

                let invitationLink = window.location.protocol + '//' + window.location.host
                +'/auth/onboard/'+item.id+'/'+item.attributes.passwordReset;

                navigator.clipboard.writeText(invitationLink);

                this.$notify({
                    group: 'notification',
                    type: 'success',
                    text: this.$ml.get('INVITATION_LINK_COPIED')
                });

            },
            niceDate(date){
                return moment(date).format('DD/MM/Y');
            },
            niceStatus(item){
                if(item.attributes.suspended) {
                    return this.$ml.get('USER_SUSPENDED');
                }else {
                    if(item.attributes.onboarded){
                        return this.$ml.get('ONBOARDED');
                    }else {
                        return this.$ml.get('INVITATION_PENDING');
                    }
                }
            },
            suspendedRow(item){
                if(this.isSuspended(item)){
                    return 'suspended';
                }else{
                    return '';
                }
            },
            suspendMesssage(item){
                if(this.isSuspended(item)){
                    return this.$ml.get('RESTORE_USER');
                }else {
                    return this.$ml.get('SUSPEND_USER');
                }
            },
            isSuspended(item){
                return item.attributes.suspended;
            },
            openEditCompany(compId){
                this.$store.commit('toggleDashboardModal', {name: 'DashboardClientsForm', data: {id: compId}})
            },
            suspendUser(id){
                this.$store.commit('toggleDashboardModal', {name: 'DashboardUserSuspend', data: {ids: [id]}})
            },
            forbidDelete(item){
                return Boolean(item.id == this.curUser.id);
            },
            toggleModal(componentName) {
                this.$store.commit('toggleDashboardModal', {name: componentName})
            },
            addNewUser(){
                this.$store.commit('toggleDashboardModal', {name: 'DashboardUserForm', data: {id: null}})
            },
            editUser(id = null) {
                this.$store.commit('toggleDashboardModal', {name: 'DashboardUserForm', data: {id: id}})
            },
            deleteUser(id) {
                // DashboardEmployeeCardsDelete
                this.$store.commit('toggleDashboardModal', {name: 'DashboardUserDelete', data: {ids: [id]}})
            },
            bulkDelete() {
                this.$store.commit('toggleDashboardModal', {
                    name: 'DashboardUserDelete',
                    data: {ids: this.selectedRowsTrue}
                })
            },
        },
        watch : {
            perPageValue(newPerPage) {
                this.$store.commit('setUsersPP', newPerPage);
                if(this.curPageValue > 1) {
                    this.curPageValue = 1;
                } else {
                    this.fetchUsers();
                }
            },
            curPageValue(newPageVal){
                this.$store.commit('setUsersPage', newPageVal);
                this.fetchUsers();
            },
            searchString(newSearch) {
                clearTimeout(this.timeout);
                let self = this;
                self.timeout = setTimeout(() => {
                    self.doSearch(newSearch);
                }, 300);
            },
        }
    }
</script>

<style scoped>

    span.status-text {
        position: relative;
        display: block;
    }

    .onboarded {
        color: var(--color-secondary);
    }

    .pending {
        color: var(--color-primary);
    }

    .per-page-select {
        width: 110px;
    }
    .suspended {
        opacity: 0.5;
    }
    .avatar {
        max-height: 32px;
        max-width: 32px;
    }
    .company-logo {
        max-height: 16px;
        max-width: 40px;
        float: left;
        margin-right: 5px;
        margin-top: -1px;
    }
</style>
