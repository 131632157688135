<template>
    <form @submit.prevent="submitForm">
        <h3 class="text-center">
            {{(id===null)?$ml.get('ADD_NEW_USER'):$ml.get('EDIT_USER_DETAILS')}}
        </h3>

        <div class="row">
            <div class="col-4">
                <dropzone-field
                    :name="'avatar'"
                    :id="'fileInput'"
                    :accept="`image/*`"
                    v-model="form.avatar">
                    <img :src="localAvatar"
                         v-if="localAvatar">
                    <template v-else>
                        <div>
                            {{$ml.get('ADD_PROFILE_PIC')}}<br>(square .jpg or .png)
                        </div>
                    </template>
                </dropzone-field>
            </div>

            <div class="col-8">
                <div class="row">
                    <div class="col-12">
                        <input-field :id="`first-name`"
                                     :name="'first-name'"
                                     :value="form.firstName"
                                     :required="true"
                                     @validation-result="validationCollector"
                                     v-model="form.firstName">{{$ml.get('FIRST_NAME')}}
                        </input-field>
                    </div>
                    <div class="col-12">
                        <input-field :id="`last-name`"
                                     :name="'last-name'"
                                     :value="form.lastName"
                                     :required="true"
                                     @validation-result="validationCollector"
                                     v-model="form.lastName">{{$ml.get('LAST_NAME')}}
                        </input-field>
                    </div>

                    <div class="col-12">
                        <input-field :id="`email`"
                                     :name="'email'"
                                     :value="form.username"
                                     :required="true"
                                     @validation-result="validationCollector"
                                     v-model="form.username">{{$ml.get('LOGIN_EMAIL')}}
                        </input-field>
                    </div>


                    <div v-if="!id && isAdmin" class="col-6 mb-2">
                        <checkbox-field color="secondary"
                                        :value="form.activateUser"
                                        v-model="form.activateUser"
                        >{{$ml.get('ACTIVATE_USER')}}
                        </checkbox-field>
                    </div>


                    <div v-if="!id && form.activateUser" class="col-12">
                        <input-field :id="`pass1`"
                                     :name="'pass1'"
                                     :type="`text`"
                                     :value="form.password"
                                     v-model="form.password">{{$ml.get('PASSWORD_OPTIONAL')}}
                        </input-field>
                    </div>
                </div>

            </div>



        </div>


        <div class="row">
            <div class="col-12">
                <label class="form-label select-2-label">{{$ml.get('LINK_TO_COMPANY')}}</label>
                <company-agency-selector
                    class="pt-3"
                    :company-id="userCompanyId"
                    :client-of="clientOfId"
                    :allow-blank="isAdmin"
                    @companyPicked="form.company.id = $event.id"
                />
            </div>

            <div class="col-12" v-if="isAdmin">
                <p class="simple-clarification">Leave empty to allow the invited user to setup their own company details.</p>
            </div>
        </div>

        <div class="row pt-2" v-if="isAdmin">
            <div class="col-6 mb-2">
                <checkbox-field color="secondary"
                                :value="form.hasTrial"
                                v-model="form.hasTrial"
                >{{$ml.get('this_is_trial_account')}}
                </checkbox-field>
            </div>
            <div class="col-6 mb-2" v-if="form.company.id === 0 || form.company.id === '0'">
                <checkbox-field color="secondary"
                                :value="form.canSetupAgency"
                                v-model="form.canSetupAgency"
                >{{$ml.get('user_can_setup_agency')}}
                </checkbox-field>
            </div>

            <div v-if="form.hasTrial" class="col-12">
                <label class="form-label form-date-label">{{$ml.get('select_trial_end_date')}}</label>
                <input type="date" class="form-field-date" v-model="form.trialUntil" :min="minDate"/>
            </div>

            <div class="col-6 mb-2" v-if="!form.canSetupAgency">
                <checkbox-field color="secondary"
                                :value="form.canEditDesign"
                                v-model="form.canEditDesign"
                >{{$ml.get('this_user_can_edit_design')}}
                </checkbox-field>
            </div>

        </div>

        <div class="row pt-4 justify-content-center">
            <div class="col-6">
                <button-field color="secondary"
                              :icon="(id===null)?'plus':'save'"
                              type="submit"
                              :disabled="isFormDisabled || loading"
                              :loading="loading"
                              block>{{(id===null)?$ml.get('ADD'):$ml.get('SAVE')}}
                </button-field>
            </div>
        </div>
    </form>
</template>

<script>
    import CompanyAgencySelector from "@/components/global/CompanyAgencySelector.vue";
    import moment from "moment";
    export default {
        name: "DashboardUserForm",
        components: {
            CompanyAgencySelector
        },
        computed: {
            isAdmin() {
                return this.$store.state.user.attributes.isAdmin;
            },
            id() {
                return this.$store.state.dashboardModalComponent.data.id
            },
            minDate() {
                return moment().format('Y-MM-DD');
            },
            selectedUser() {
                return this.$store.getters.selectedModalData('visibleUsers');
            },


            companies() {
                let companies = [];

                if(this.isAdmin){
                    companies.push({
                        id: 0,
                        text: "None",
                        fullData: {
                            name: "None",
                            logo: {
                                _url: require('../../assets/noun-empty.svg')
                            }
                        }
                    });
                }

                if(this.$store.state.agencyClients){
                    this.$store.state.agencyClients.forEach((comp) => {
                        let element = {
                            id: comp.id,
                            text: comp.attributes.name,
                            fullData: comp.attributes
                        };
                        companies.push(element);
                    })
                }
                return companies;
            },
            isFormDisabled(){
                return this.errors.length > 0;
            }
        },
        data() {
            return {
                theId: null,
                clientOfId: null,
                userCompanyId: null,
                localAvatar: null,
                form: {
                    activateUser: false,
                    id : null,
                    username: '',
                    firstName: '',
                    lastName:  '',
                    mobilePhone: '',
                    canSetupAgency: false,
                    canEditDesign: false,
                    company: {id: 0},
                    randString: '',
                    hasTrial: false,
                    trialUntil: null,
                    select: {
                        selectError: '',
                        options: [
                            {
                                value: 'initState',
                                text: '',
                                disabled: true
                            }
                        ],
                        value: 'initState'
                    }
                },
                loading: false,
                errors: []
            };
        },
        created() {

            if (this.id !== null) {

                if(this.form.trialUntil) {
                    this.form.hasTrial = true;
                    this.form.trialUntil = moment(this.form.trialUntil).format('Y-MM-DD');
                }

            }else {

                this.userCompanyId = this.$store.state.user.attributes.company.id;
                this.form.company = {};
                this.form.company.id = this.userCompanyId;

            }
        },
        methods: {

            selectionPreview(selection) {
                let markup = '';

                if (selection.children && selection.children.length > 0) {
                    // It's an agency with children
                    let agency = selection.companyObj.attributes;
                    if (!agency.logo) {
                        agency.logo = {
                            _url: this.companyPlaceholder
                        }
                    }

                    markup += `<div class="agency selection">`;
                    markup += `<span class="profile-avatar">`;
                    markup += `<img class="comp-logo" src="` + agency.logo._url + `" />`;
                    markup += `</span>`;
                    markup += `<h4>` + selection.text + `</h4>`;
                    markup += `</div>`;
                } else if (selection.fullData) {
                    let item = selection.fullData;

                    if (!item.logo) {
                        item.logo = {
                            _url: this.companyPlaceholder
                        }
                    }

                    markup += selection.isOwnCompany ? `<div class="company selection own">` : `<div class="company selection">`;

                    markup += `<span class="profile-avatar">`;
                    markup += `<img class="comp-logo" src="` + item.logo._url + `" />`;
                    markup += `</span>`;
                    markup += `<h4>` + item.name + `</h4>`;
                    markup += `</div>`;
                }

                return markup;
            },

            companyPreview(choice) {
                let markup = '';

                if (choice.isAgency) {
                    let agency = choice.fullData;
                    if (!agency.logo) {
                        agency.logo = {
                            _url: this.companyPlaceholder
                        }
                    }

                    markup += `<div class="agency" data-agency-id="${choice.id}">`;
                    markup += `<span class="profile-avatar">`;
                    markup += `<img class="comp-logo" src="` + agency.logo._url + `" />`;
                    markup += `</span>`;
                    markup += `<h4 class="d-inline">` + choice.text + `</h4>`;
                    markup += `</div>`;
                } else if (choice.fullData) {
                    let item = choice.fullData;

                    if (!item.logo) {
                        item.logo = {
                            _url: this.companyPlaceholder
                        }
                    }

                    if (item.suspended) {
                        markup = `<div class="company suspended">`;
                    } else {
                        markup += choice.isOwnCompany ? `<div class="company own">` : `<div class="company">`;
                    }

                    markup += `<span class="profile-avatar">`;
                    markup += `<img class="comp-logo" src="` + item.logo._url + `" />`;
                    markup += `</span>`;
                    markup += `<h4>` + item.name + `</h4>`;
                    markup += `</div>`;
                }

                return markup;
            },
            validationCollector(inputValidation){
                Array.prototype.remove = function() {
                    var what, a = arguments, L = a.length, ax;
                    while (L && this.length) {
                        what = a[--L];
                        while ((ax = this.indexOf(what)) !== -1) {
                            this.splice(ax, 1);
                        }
                    }
                    return this;
                };

                if(inputValidation.valid){
                    this.errors.remove(inputValidation.name);
                }else {
                    if(this.errors.indexOf(inputValidation.name) === -1){
                        this.errors.push(inputValidation.name);
                    }
                }
            },

            generateRandomString(length) {
                let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
                let result = '';
                for (let i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
                return result;
            },

            submitForm() {
                let self = this;
                if (!this.loading) {
                    this.loading = true;
                    this.form.randString = this.generateRandomString(20);

                    this.$store.dispatch('saveUser', {id: this.id, ...this.form}).then(() => {
                        this.$store.dispatch('getAvailableUsers').then(() => {
                            this.loading = false;
                            this.$notify({
                                group: 'notification',
                                type: 'success',
                                text: this.$ml.get('notification_success_saved'),
                            });
                            this.$store.commit('toggleDashboardModal');
                        });
                    }).catch((e) => {
                        //console.log("An error has occurred", e.message);
                        this.$notify({
                            group: 'notification',
                            type: 'error',
                            title: this.$ml.get('notification_error_title'),
                            text: this.$ml.get('notification_error_general'),
                        });
                        self.$checkTokenExp(e);
                        this.loading = false;
                    });

                }
            }
        },
        watch: {
            selectedUser: {
                handler(newUserObj, oldUserObj) {
                    if (newUserObj !== null && (typeof oldUserObj == 'undefined' || (newUserObj.id !== oldUserObj.id)) ) {

                        this.form = {...this.form, ...newUserObj.attributes}
                        if(newUserObj.attributes.avatar){
                            this.localAvatar = newUserObj.attributes.avatar._url;
                        }
                    }
                    this.form.id = newUserObj ? newUserObj.id : null;

                    const keysToRemove = ['lastLoginAt', 'ACL', 'avatar', 'company', 'createdAt' ];
                    keysToRemove.forEach(key => {
                        delete this.form[key];
                    });

                    if(!newUserObj){
                        this.form.password = this.generateRandomString(20);
                    }

                    if(newUserObj && newUserObj.attributes && newUserObj.attributes.company){
                        this.form.company = {};
                        this.form.company.id = newUserObj.attributes.company.id;
                        this.userCompanyId = newUserObj.attributes.company.id;


                        if(newUserObj.attributes.company.attributes.isAgency){
                            this.clientOfId = newUserObj.attributes.company.id;
                        } else {
                            this.clientOfId = newUserObj.attributes.company.attributes.clientOf ? newUserObj.attributes.company.attributes.clientOf.id : null;
                        }
                    }

                },
                immediate: true,
                deep: true
            },
            id: {
                handler(newUserId) {
                    let self = this;
                    if(newUserId){
                        self.$store.dispatch('getUserEmail', {
                            userId: newUserId
                        }).then(response => {
                            if(response.email){
                                self.form.email = response.email;
                            }
                        });
                    }
                },
                immediate: true,
                deep: true
            }
        }
    }
</script>


<style scoped>
.select2-results__option .agency {
    font-size: 12px;
    display: flex;
    align-content: center;
    justify-content: flex-start;
}

.agency h4 {
    margin: auto 0;
    color: black;
}

.company h4 {
    margin: auto 0;
    font-size: 12px;
    font-weight: inherit;
}

.dropzone-container {
    height: 180px !important;
}

.select2-container{
    width: 100% !important;
}

.simple-clarification {
    color: #999999;
    font-style: italic;
    font-size: 0.75em;
}
.form-label {
    position: relative;
    width: 100%;
    color: var(--color-dark);
    /* transition: all 0.25s ease-in-out; */
    text-transform: uppercase;
    font-size: 10px;
    cursor: text;
    pointer-events: none;
}

.color-error > .form-label,
.form-has-value > .form-label {
    top: 0;
    font-size: 10px;
}

.form-field:focus ~ .form-label {
    top: 0;
    font-size: 10px;
}
</style>

<style>
.select2-container--default .select2-results__option .select2-results__option {
    padding-left: 1.25em;
}

.select2-results__option .agency {
    font-size: 12px;
    display: flex;
    align-content: center;
    justify-content: flex-start;
}

.agency h4 {
    margin: auto 0;
    color: black;
}

.company h4 {
    margin: auto 0;
    font-size: 12px;
    font-weight: inherit;
}

li.select2-results__option .company {
    padding-left: 1.25em;
}
</style>
